.ant-input{
  font-size: 14px !important;
}
// .yscroll{
//   overflow-x: auto;
//     overflow-y: hidden;
// }

.ant-table-thead{
background: #eaeaea !important;
}


.ant-table-thead .ant-input{
  border:0px !important;
  border-radius: 4px !important;
}
.ant-table-thead > tr > th{
  border-bottom:0px!important;
  background:transparent !important;
  padding:0px !important;
  text-align: center !important;
}

.ant-form-item-label{
  line-height: 32px !important;
}
.content-style{
  width: 100%;
  height: 100%;
  // overflow: overlay;
}
.ant-table-thead > tr > th .ant-table-header-column {
  width: 100%;
  padding:10px 3px 0px 3px;
  
}

.ant-table-thead > tr > th:first-child .ant-table-header-column {

  padding-left:12px;
}

.ant-table-thead > tr > th:last-child .ant-table-header-column {

  padding-right:12px;
}

.ant-select-sm .ant-select-selection--single {
  height: 28px !important;
}
.ant-table-column-title > div > div > span{
  height: 28px !important;
}
.ant-table-column-title > div > div > span > div > input {
  height: 28px !important;
}
.ant-table-tbody tr{
  border-radius: 6px !important;
}
.ant-table-tbody tr > td{
  border:0px !important;
}
.ant-table-tbody tr:nth-child(odd) {
  background-color: #ffffff !important;
}
.ant-table-tbody tr:nth-child(even) {
  background-color: #f3f3f3 !important;
}
.ant-table-tbody td:not(.original-style-input-table){
padding:0px;
  height:35px !important; 
}
.ant-table-tbody td:not(.original-style-input-table) div{
  font-size: 12px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  font-weight: 500;
}
.ant-calendar-picker {
  width: 100% !important;
}

.clickable-item {
  cursor:pointer;
  &:hover{
    background-color: #e0e0e0;
  }
  
  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 0px !important;
  }
  .ant-table-thead .ant-input{
    border-top:0.5px solid #ccc !important;
    border-bottom:1px solid #ccc !important;
    border-right:0px solid #ccc !important;
    border-left:0.5px solid #ccc !important;
    border-radius: 0px !important;
  }
  .ant-table-thead > tr > th{
    border-bottom:0.5px solid #ccc !important;
  }
  .ant-select-selection{
    border-radius:0px !important;
  }
  .content-style{
    width: 100%;
    height: 100%;
  }
  .ant-table-thead > tr > th .ant-table-header-column {
    width: 100%;
  }
  .ant-select-sm .ant-select-selection--single {
    height: 40px !important;
  }
  .ant-table-column-title > div > div > span > div > input {
    height: 40px;
  }
  
  
  
  .clickable-item {
    cursor:pointer;
    &:hover{
      background-color: #e0e0e0;
    }
  }
  .flex-1{
    // -webkit-flex: 1 1 auto;
    flex:1;
    @media screen and (max-width: 800px) {
      flex-basis: auto;
    }
  }
  
  .flex-space-between{
    justify-content: space-between;
  }
  .filter-input {
    height: 25px;
    width: 100%;
  }
  .filter-input:focus {
    outline: none;
  }
  
  
  .ant-table-thead .icon{height: 31.8px !important}
  
  .ant-input:focus {
    outline: none !important;
  }
  .ant-calendar-picker {
    width: 100% !important;
  }
  .ant-calendar-picker-icon{margin-right: 5px;}
  .ant-input-number {
    width: 100% !important;
  }
  
  .ant-select-selection--single:focus {
    outline: none !important;
  }
  .ant-calendar-picker-input{
    background-color: #fafafa !important;
    border: 0px solid #d9d9d9 !important;
    border-top-width: 0px;
  }
  
  .ant-btn-group {
    position: relative !important;
    width: 100% !important;
  }
  .ant-btn-group:hover {
    .ant-dropdown-trigger {
      display: inline-block !important;
    }
  }
  .ant-btn-group, .ant-btn-default {
    border: none !important;
    padding: 0px !important;
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  .ant-dropdown-trigger {
    display: none !important;
    position: absolute !important;
    right: 0 !important;
  }
  .ant-dropdown-menu-submenu-vertical .ant-dropdown-menu-submenu {
    padding: 0px !important;
  }
  .ant-menu-item, .ant-menu-submenu {
    line-height: 30px !important;
    height: 30px !important;
    padding: 0px 20px !important;
    width: 170px !important;
  }
  .ant-menu-item-group {
    .ant-menu-item-group-title {
      padding: 0px !important;
    }
  }
  .ant-menu-root {
    box-shadow: 1px 18px 40px -18px rgba(0,0,0,0.75) !important;
  }
  .ant-menu-submenu-vertical {
    .ant-menu-submenu-title {
      padding: 0px !important;
      margin: 0px !important;
      line-height: 30px !important;
    }
  }
  .ant-menu-vertical .ant-menu-item:after {
    content: inherit !important;
  }
  .ant-btn-default {
    background: transparent !important;
  }
  
  table.scroll {
    border-collapse: collapse;
    thead {
      padding: 0px;
      // display: block;
      td {
        background: #f2f2f2;
        border: 1px #ccc solid;
        overflow: hidden;
        input, .ant-select {
          border: 0px !important;
        }
      }
    }
    tbody {
      overflow-y: scroll;
      max-height: 200px;
      tr {
        border: 1px #ccc solid;
      }
    }
  }
  
  .table-wrapper {
    position: relative;
    height: 55vh;
    overflow: scroll;
  }
  .table-content-wrapper {
    position: relative;
    height: 20vh;
    overflow: scroll;
  }
  
  
  
  .ant-table{font-size: 12px !important;}
  
  .ant-table-thead>tr>th {
    padding: 0px !important;
    .anticon-filter{
      margin-left: 0!important;
      font-size: 15px;
    }
  }
  .ant-dropdown-trigger {
    display: block !important;
    position: relative !important;
    color: #000;
  }
  
  .ant-table-tbody {
    tr:nth-child(odd){background-color: #f3f3f3}
    tr:nth-child(even){background-color: #ffffff}
    td:not(.original-style-input-table) {
      position: relative;
      height: 40px;
      padding: 5px 8px !important;
      font-weight: 500;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        line-height: 35px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 14px;
      }
    }
  }
  
  .original-style-input-table{
    padding: 2px !important;
  }
  
  
  .subcontent-table .ant-table-tbody tr{
    td:last-of-type {
      background: none!important;
      border-bottom: 1px solid #e9e9e9;
    }
    &:hover > td{
      background: #ecf6fd!important;
    }
  }
  
  th {
    white-space: nowrap;
    overflow: hidden;
  }
  .column-title {
    padding: 10px 8px;
    position: relative;
    font-size: 15px;
    // border-top: 0px;
    // border-bottom: 0px;
    // border-right: 0px #ccc solid;
    // border-left: 0.5px #ccc solid;
    .column-icon {
      display: none;
      cursor: pointer;
      position: absolute;
      right: -1px;
      top: -1px;
      bottom: 0px;
    }
    .icon {
      color: #EFEFF7;
      width: 20px;
      padding: 10px 7px;
      font-size: 8px;
      background: #85B3E4;
    }
    // &:last-of-type {
    //   background-color: #f5f5f5!important;
    //   border: none;
    // }
  }
}


.original-style-input-table{
  padding: 2px !important;
}


.subcontent-table .ant-table-tbody tr{
  td:last-of-type {
    background: none!important;
    border-bottom: 1px solid #e9e9e9;
  }
  .active {
    display: block !important;
  }
  .column-title:hover {
    .column-icon {
      display: block;
    }
  }
}

th {
  white-space: nowrap;
  overflow: hidden;
}
.column-title {
  padding: 6px 0px;
  text-align: left;
  position: relative;
  font-size: 12px;
  // border-top: 0px;
  // border-bottom: 0px;
  // border-right: 0px #ccc solid;
  // border-left: 0.5px #ccc solid;
  .column-icon {
    display: none;
    cursor: pointer;
    position: absolute;
    right: -1px;
    top: -1px;
    bottom: 0px;
  }
  .icon {
    color: #EFEFF7;
    width: 20px;
    padding: 10px 7px;
    font-size: 8px;
    background: #85B3E4;
  }
  .ant-pagination {
    li {
      line-height: 30px !important;
      vertical-align: middle;
    }
    
    margin: 16px!important;
    margin-top: 8px !important;
    &.pagination{
      position: absolute;
      right: 0;
      bottom: 0px;
    }
  }
  
  .ant-table-header {
    background: transparent;
    background-color: transparent;
    margin-bottom: -6px !important;
    min-width: 0px !important;
    thead > tr {
      height: 0 !important;
    }
  }
  
  
  .font-normal{
    font-style: normal !important;
    vertical-align: top;
    display: inline-block;
    max-width: 55%;
  }
  .ant-table-placeholder{
    height: calc(30vh - 90px);
  }
}
.ant-table table{
  border-radius: 6px !important;
}
.ant-table-header {
  background: none;
  background-color: transparent !important;
  margin-bottom: 0px !important;
  min-width: 0px !important;
  overflow:hidden !important;
  border-radius: 6px !important;
  padding: 0px;
  padding-right: 4px;
  thead > tr {
    height: 0 !important;
  }
  
  .shelf-life-header {
    // height: 50px;
      padding: 25px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #9c9c9c;
    border-top: 1px solid #9c9c9c;
    .shelf-life-container {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .Select{
        margin: 0 10px;
      }
    }
  }
  
  .select-item {
    background: #d2d2d2;
  }
  
  .last .ant-menu-submenu-vertical {
    .ant-menu {
      left: -100% !important;
    }
  }
}

.cssload-piano {
	margin: auto;
  margin-top: 150px;
	width: 39px;
	height: 10px;
	font-size: 10px;
}
.cssload-piano > div {
	height: 100%;
	width: 100%;
	display: block;
	margin-bottom: 0.6em;
	animation: stretchdelay 1.38s infinite ease-in-out;
		-o-animation: stretchdelay 1.38s infinite ease-in-out;
		-ms-animation: stretchdelay 1.38s infinite ease-in-out;
		-webkit-animation: stretchdelay 1.38s infinite ease-in-out;
		-moz-animation: stretchdelay 1.38s infinite ease-in-out;
}
.cssload-piano .cssload-rect2 {
	animation-delay: -1.15s;
		-o-animation-delay: -1.15s;
		-ms-animation-delay: -1.15s;
		-webkit-animation-delay: -1.15s;
		-moz-animation-delay: -1.15s;
}
.cssload-piano .cssload-rect3 {
	animation-delay: -0.92s;
		-o-animation-delay: -0.92s;
		-ms-animation-delay: -0.92s;
		-webkit-animation-delay: -0.92s;
		-moz-animation-delay: -0.92s;
}



@keyframes stretchdelay {
	0%, 40%, 100% {
		transform: scaleX(0.8);
		background-color: rgb(16,143,233);
		box-shadow: 0 0 0 rgba(10,10,10,0.1);
	}
	20% {
		transform: scaleX(1);
		background-color: rgb(0,177,146);
		box-shadow: 0 5px 6px rgba(10,10,10,0.4);
	}
}

@-o-keyframes stretchdelay {
	0%, 40%, 100% {
		-o-transform: scaleX(0.8);
		background-color: rgb(16,143,233);
		box-shadow: 0 0 0 rgba(10,10,10,0.1);
	}
	20% {
		-o-transform: scaleX(1);
		background-color: rgb(0,177,146);
		box-shadow: 0 5px 6px rgba(10,10,10,0.4);
	}
}

@-ms-keyframes stretchdelay {
	0%, 40%, 100% {
		-ms-transform: scaleX(0.8);
		background-color: rgb(16,143,233);
		box-shadow: 0 0 0 rgba(10,10,10,0.1);
	}
	20% {
		-ms-transform: scaleX(1);
		background-color: rgb(0,177,146);
		box-shadow: 0 5px 6px rgba(10,10,10,0.4);
	}
}

@-webkit-keyframes stretchdelay {
	0%, 40%, 100% {
		-webkit-transform: scaleX(0.8);
		background-color: rgb(16,143,233);
		box-shadow: 0 0 0 rgba(10,10,10,0.1);
	}
	20% {
		-webkit-transform: scaleX(1);
		background-color: rgb(0,177,146);
		box-shadow: 0 5px 6px rgba(10,10,10,0.4);
	}
}

@-moz-keyframes stretchdelay {
	0%, 40%, 100% {
		-moz-transform: scaleX(0.8);
		background-color: rgb(16,143,233);
		box-shadow: 0 0 0 rgba(10,10,10,0.1);
	}
	20% {
		-moz-transform: scaleX(1);
		background-color: rgb(0,177,146);
		box-shadow: 0 5px 6px rgba(10,10,10,0.4);
	}
}


@media screen and (max-width: 1024px) {
  .main-table-small2{
    .ant-table-body > table{
      font-size: 12px !important;
    }
    .ant-table-header> table{
      font-size: 12px !important;
      font-weight: bold !important;
    }
}
}

.eor-filter-date{
  div> div{
    margin: 0px !important;
  }
}

.table-width-forresponsive {
  height: 70vh;
}

@media only screen and (min-width:1440px) {

}
@media only screen and (max-width:1280px) {

}
@media only screen and (max-width:1024px) {

}
@media only screen and (max-width:834px) {

}
@media only screen and (max-width:768px) {

}
@media only screen and (max-width:414px) {
  
}
@media only screen and (max-width:375px) {

}



// chat ticket list page 

.table-ticket_chat > div > div > div > div > div > div.ant-table-body > table > tbody > tr > td:nth-child(6) > div{
  margin-top:1px;
height:33px;
width: 95%;
line-height: 33px;
display: inline-block !important;
}

.ant-table-thead > tr:first-child > th:last-child{
  border-top-right-radius: 6px !important;
}
.table-layout .ticket-chat-menu{
  margin-bottom: 0px !important;
}
// chat ticket list page END

.sales-order-table-btn {
  display: flex !important;
  align-items: center;
  width: 100%;
  cursor: pointer;
  span {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
