.cardstyle {
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: overlay;
    width: 100%;
    background-color: #ffffff;
    margin: 0;
}

.header-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
}

.image-upload-container .ant-row-flex{
    flex-flow: unset;
    
}

@media only screen and (max-width:576px) {
    .header-card {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}
@media only screen and (min-width:576px) {
    
}




.product-sku-table .ant-table-row{
    height: 50px !important;
}

.product-sku-column-action{
    display: flex;
    justify-content: flex-end;
    gap: 12px !important;
    padding: 0 12px !important;
}

.product-sku-modal-align-left .ant-form-item-label{
    text-align: left;
}

.product-sku-table .ant-table-body .ant-table-tbody .ant-table-row-cell-break-word {
    // ปลด limit height
    height: auto !important;
}

.block{
    display: block !important;
}

.card-align-left{
    text-align: left !important;
}

.w-100 .ant-select{
    width: 100%;
}