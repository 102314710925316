/* @font-face {
  font-family: 'Sarabun';
  src: local('Sarabun'), url(../src/resource/fonts/Sarabun-SemiBold.ttf) format('truetype');
} */

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'Sarabun', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-page-wrapper {
  display: flex;
  flex-direction: row; }
  .login-page-wrapper .left-colunm-signin-wrapper {
    background-color: #65D2F3;
    background-image: url(/static/media/loginleftbg.b2133727.png);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 100px 0 50px 5%; }
    @media only screen and (max-width: 834px) {
      .login-page-wrapper .left-colunm-signin-wrapper {
        display: none; } }
    .login-page-wrapper .left-colunm-signin-wrapper .logo {
      position: absolute;
      top: 8%; }
    .login-page-wrapper .left-colunm-signin-wrapper .title {
      margin: 10px 0;
      font-size: 70px;
      font-weight: 400; }
    .login-page-wrapper .left-colunm-signin-wrapper .border-title {
      border-bottom: 12px solid #48c5eb;
      margin-left: 12px;
      width: 100px;
      margin-bottom: 30 px; }
    .login-page-wrapper .left-colunm-signin-wrapper .sub-title {
      font-size: 32px;
      line-height: 50px;
      font-weight: 400;
      margin: 0;
      color: #fff; }
    .login-page-wrapper .left-colunm-signin-wrapper .sub-title-2 {
      font-size: 42px;
      line-height: 50px;
      font-weight: 700;
      margin: 0;
      color: #fff; }
    .login-page-wrapper .left-colunm-signin-wrapper .sub-title-3 {
      font-size: 18px;
      line-height: 2.5rem;
      font-weight: 400;
      margin: 0;
      margin-top: 70px;
      color: #fff; }
    .login-page-wrapper .left-colunm-signin-wrapper .signup-button {
      background-color: #ffffff;
      border-radius: 20px;
      margin-top: 20px;
      padding: 10px 20px;
      max-width: 200px;
      font-size: 18px;
      text-align: center;
      font-weight: 500;
      transition: 0.3s; }
      .login-page-wrapper .left-colunm-signin-wrapper .signup-button:hover {
        background-color: #e4e4e4; }
      .login-page-wrapper .left-colunm-signin-wrapper .signup-button p {
        margin: 0; }
    .login-page-wrapper .left-colunm-signin-wrapper .login-footer {
      position: absolute;
      bottom: 8%;
      text-align: left;
      color: #fff; }
  .login-page-wrapper .right-colunm-signin-wrapper {
    background-color: #fcfcfc;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; }
    .login-page-wrapper .right-colunm-signin-wrapper .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .login-page-wrapper .right-colunm-signin-wrapper .container .logo {
        display: none; }
      .login-page-wrapper .right-colunm-signin-wrapper .container .title {
        margin: 10px 0;
        font-size: 55px;
        font-weight: 400; }
        .login-page-wrapper .right-colunm-signin-wrapper .container .title .title-bold {
          font-weight: 500; }
      .login-page-wrapper .right-colunm-signin-wrapper .container .sub-title {
        font-size: 18px;
        line-height: 2.5rem;
        font-weight: 400;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 10px;
        text-align: center; }
      .login-page-wrapper .right-colunm-signin-wrapper .container .logo {
        text-align: center;
        width: 100%;
        margin-bottom: 50px; }
        .login-page-wrapper .right-colunm-signin-wrapper .container .logo img {
          max-width: 100%; }
      .login-page-wrapper .right-colunm-signin-wrapper .container .input-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 450px;
        padding: 20px;
        padding-top: 0; }
        .login-page-wrapper .right-colunm-signin-wrapper .container .input-group .input-login {
          margin-bottom: 10px; }
        .login-page-wrapper .right-colunm-signin-wrapper .container .input-group .input-login::-webkit-input-placeholder {
          color: #eaeaea; }
        .login-page-wrapper .right-colunm-signin-wrapper .container .input-group .input-login::placeholder {
          color: #eaeaea; }
        .login-page-wrapper .right-colunm-signin-wrapper .container .input-group .ant-input-affix-wrapper {
          font-size: 20px !important; }
        .login-page-wrapper .right-colunm-signin-wrapper .container .input-group .ant-input-lg {
          height: 55px;
          font-size: 20px !important;
          padding-left: 40px !important; }
        .login-page-wrapper .right-colunm-signin-wrapper .container .input-group button {
          padding: 15px 10px !important;
          margin: 20px 0;
          border-radius: 4px !important;
          font-size: 18px !important;
          border: none;
          background: #00c4ff;
          background: linear-gradient(0deg, #00c4ff 0%, #3fc5ed 100%);
          color: #fff;
          font-weight: 500; }

@media only screen and (min-width: 1440px) {
  .input-group {
    width: 60%; } }

@media only screen and (max-width: 1024px) {
  .login-page-wrapper .left-colunm-signin-wrapper {
    justify-content: center;
    padding: 50px; }
  .login-page-wrapper .left-colunm-signin-wrapper .login-footer {
    margin-right: 50px; } }

@media only screen and (max-width: 834px) {
  .left-colunm-signin-wrapper {
    display: none !important; }
  .right-colunm-signin-wrapper {
    width: 100% !important; }
    .right-colunm-signin-wrapper .container .logo {
      display: block !important; } }

@media only screen and (max-width: 414px) {
  .login-page-wrapper .right-colunm-signin-wrapper .container .input-group {
    width: 100%; } }

.login-page-wrapper {
  display: flex;
  flex-direction: row; }
  .login-page-wrapper .left-colunm-wrapper-login {
    background-color: #65D2F3;
    background-image: url(/static/media/loginleftbg.b2133727.png);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 100px 0 50px 5%; }
    @media only screen and (max-width: 834px) {
      .login-page-wrapper .left-colunm-wrapper-login {
        display: none; } }
    .login-page-wrapper .left-colunm-wrapper-login .logo {
      position: absolute;
      top: 8%; }
    .login-page-wrapper .left-colunm-wrapper-login .title {
      margin: 10px 0;
      font-size: 70px;
      font-weight: 400; }
    .login-page-wrapper .left-colunm-wrapper-login .border-title {
      border-bottom: 12px solid #48c5eb;
      margin-left: 12px;
      width: 100px;
      margin-bottom: 30 px; }
    .login-page-wrapper .left-colunm-wrapper-login .sub-title {
      font-size: 32px;
      line-height: 50px;
      font-weight: 400;
      margin: 0;
      color: #fff; }
    .login-page-wrapper .left-colunm-wrapper-login .sub-title-2 {
      font-size: 42px;
      line-height: 50px;
      font-weight: 700;
      margin: 0;
      color: #fff;
      margin-bottom: 100px; }
    .login-page-wrapper .left-colunm-wrapper-login .sub-title-3 {
      font-size: 18px;
      line-height: 2.5rem;
      font-weight: 400;
      margin: 0;
      margin-top: 70px;
      color: #fff; }
    .login-page-wrapper .left-colunm-wrapper-login .signin-button {
      background-color: #ffffff;
      border-radius: 20px;
      padding: 10px 20px; }
      .login-page-wrapper .left-colunm-wrapper-login .signin-button p {
        margin: 0; }
    .login-page-wrapper .left-colunm-wrapper-login .login-footer {
      position: absolute;
      bottom: 8%;
      text-align: left;
      color: #fff; }
  .login-page-wrapper .right-colunm-wrapper {
    background-color: #fcfcfc;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; }
    .login-page-wrapper .right-colunm-wrapper .header-wrapper {
      background-color: #ffffff;
      position: absolute;
      top: 0px;
      width: 100%;
      display: flex;
      height: 50px;
      border-bottom: 1px solid #ebebeb;
      justify-content: flex-end;
      align-items: center; }
      .login-page-wrapper .right-colunm-wrapper .header-wrapper .ant-row {
        width: 100%; }
      .login-page-wrapper .right-colunm-wrapper .header-wrapper .header-wrapper-left {
        display: inline-block; }
        .login-page-wrapper .right-colunm-wrapper .header-wrapper .header-wrapper-left .logo {
          display: none; }
          @media only screen and (max-width: 834px) {
            .login-page-wrapper .right-colunm-wrapper .header-wrapper .header-wrapper-left .logo {
              display: block;
              padding-top: 9px;
              padding-left: 10px; } }
      .login-page-wrapper .right-colunm-wrapper .header-wrapper .header-wrapper-right {
        text-align: right;
        display: inline-block; }
        @media only screen and (max-width: 834px) {
          .login-page-wrapper .right-colunm-wrapper .header-wrapper .header-wrapper-right {
            float: right; } }
      .login-page-wrapper .right-colunm-wrapper .header-wrapper .name {
        color: #626262;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 7px;
        display: inline-block; }
        @media only screen and (max-width: 834px) {
          .login-page-wrapper .right-colunm-wrapper .header-wrapper .name {
            display: none; } }
      .login-page-wrapper .right-colunm-wrapper .header-wrapper .popover {
        margin: 10px 30px;
        display: inline-block; }
      .login-page-wrapper .right-colunm-wrapper .header-wrapper .logout {
        margin-right: 30px;
        display: inline-block; }
      .login-page-wrapper .right-colunm-wrapper .header-wrapper .ant-badge {
        font-size: 22px;
        cursor: pointer; }
      .login-page-wrapper .right-colunm-wrapper .header-wrapper a {
        color: #626262; }
      .login-page-wrapper .right-colunm-wrapper .header-wrapper .anticon {
        font-size: 22px; }
    .login-page-wrapper .right-colunm-wrapper .email-confirmed-wrapper {
      position: absolute;
      top: 60px;
      width: 100%;
      display: flex;
      padding: 10px 20px; }
      .login-page-wrapper .right-colunm-wrapper .email-confirmed-wrapper .ant-alert-warning {
        background-color: #fffbe6;
        border: 1px solid #ffe58f;
        width: 100%; }
      .login-page-wrapper .right-colunm-wrapper .email-confirmed-wrapper .email-confirmed {
        color: #ffffff; }
    .login-page-wrapper .right-colunm-wrapper .container-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .login-page-wrapper .right-colunm-wrapper .container-wrapper .company-name-list-wrapper .item-company-name-wrapper {
        display: flex;
        border: 1px solid #d9d9d9;
        padding: 20px;
        margin: 5px;
        font-size: 20px;
        color: #000000;
        cursor: pointer;
        height: 65px;
        width: 400px; }
        .login-page-wrapper .right-colunm-wrapper .container-wrapper .company-name-list-wrapper .item-company-name-wrapper .anticon {
          position: absolute;
          right: 20px;
          font-size: 18px;
          margin-top: 3px; }
        .login-page-wrapper .right-colunm-wrapper .container-wrapper .company-name-list-wrapper .item-company-name-wrapper span {
          font-size: 18px; }
      .login-page-wrapper .right-colunm-wrapper .container-wrapper .company-name-list-wrapper .item-company-name-wrapper:hover {
        border: 1px solid #a4e4f7;
        background-color: #edf8fb;
        color: #48c5eb; }
      .login-page-wrapper .right-colunm-wrapper .container-wrapper .container-empty-wrapper .description-wrapper {
        text-align: center;
        color: #e3e3e3;
        opacity: 0.5; }
        .login-page-wrapper .right-colunm-wrapper .container-wrapper .container-empty-wrapper .description-wrapper .title {
          font-size: 18px; }
        .login-page-wrapper .right-colunm-wrapper .container-wrapper .container-empty-wrapper .description-wrapper .detail {
          font-size: 12px; }

.ant-popover-inner-content {
  cursor: default; }

.notification-wrapper {
  width: 400px; }
  .notification-wrapper .item-notification-wrapper .button-wrapper {
    display: flex; }
    .notification-wrapper .item-notification-wrapper .button-wrapper button {
      margin-top: 10px;
      margin-right: 10px; }
  .notification-wrapper .item-notification-wrapper .title {
    font-size: 14px;
    margin: 5px; }
  .notification-wrapper .item-notification-wrapper p {
    font-size: 12px;
    margin: 0px;
    text-align: left; }

.notification-empty-wrapper {
  width: 400px; }

@media only screen and (min-width: 1440px) {
  .login-page-wrapper .left-colunm-wrapper {
    padding: 150px 0 50px 100px; }
  .input-group {
    width: 60%; } }

@media only screen and (max-width: 1024px) {
  .login-page-wrapper .left-colunm-wrapper {
    justify-content: center;
    padding: 50px; }
  .login-page-wrapper .left-colunm-wrapper .login-footer {
    margin-right: 50px; } }

@media only screen and (max-width: 834px) {
  .left-colunm-wrapper {
    display: none !important; }
  .right-colunm-wrapper {
    width: 100% !important; } }

@media only screen and (max-width: 414px) {
  .login-page-wrapper .right-colunm-wrapper .container .input-group {
    width: 100%; } }

.leftmenu-page-wrapper {
  min-height: 100vh;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden; }
  .leftmenu-page-wrapper .logo {
    text-align: center;
    margin-bottom: 10px; }
  .leftmenu-page-wrapper .profile-wrapper {
    margin-bottom: 30px; }
    .leftmenu-page-wrapper .profile-wrapper .avatar {
      background-color: "#E6F7FF"; }
  .leftmenu-page-wrapper .item-menu-wrapper {
    text-align: start; }
    .leftmenu-page-wrapper .item-menu-wrapper .icon-menu {
      margin-right: 10px; }
  .leftmenu-page-wrapper .new-menu .left-menu-bottom {
    border-top: 1px solid #f0f0f0;
    margin-top: 15px;
    padding-top: 15px; }

.hover-button:hover {
  color: #000;
  background-color: #47c5eb; }

.btn-logout-section {
  margin-top: 20px; }
  .btn-logout-section .btn-logout {
    color: #fff;
    background-color: #b9b9b9;
    border: none;
    padding: 5px 25px;
    border-radius: 5px;
    cursor: pointer; }

.broadcast-memu:hover p {
  color: #1890ff !important; }

.leftmenu-btn-hover:hover {
  color: #FFF !important;
  background-color: #47c5eb !important; }

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 0px !important; }

.ant-menu-submenu-title, .ant-menu-item {
  text-align: left; }

.ant-menu-item, .ant-menu-submenu-title {
  border-radius: 8px !important; }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff !important; }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a {
  color: rgba(0, 0, 0, 0.65); }

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  display: none !important; }

#root > div > div > div.left-colunm-wrapper > div > div.new-menu > ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline > li:nth-child(4) > div.ant-menu-submenu-title > i {
  display: none; }


.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div > div > textarea {
  margin: 10px !important; }

.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div > div > dev > i {
  display: block;
  margin-bottom: 40px; }

.ant-popover-inner-content {
  cursor: pointer !important; }

#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div:nth-child(1) {
  padding-bottom: 120px !important;
  height: 100% !important; }

#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div:nth-child(2) {
  height: 120px !important;
  position: absolute !important; }

#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div > div > textarea {
  height: 100px !important; }

#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div {
  padding: 0px; }

#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div:nth-child(1) > div > div > div:nth-child(10) > div > div > div > span {
  font-size: 12px !important; }

.ant-form-item, .ant-select, .ant-select-dropdown, .ant-btn {
  font-size: 12px !important; }

.ticket-chat-page-wrapper {
  font-size: 12px !important; }
  .ticket-chat-page-wrapper .ant-select-selection {
    border-radius: 4px !important;
    border: 0px !important;
    background: #f5f5f5 !important; }

.ticket-chat-page-wrapper > div.ant-col-6 > div > div > div > div > div.ant-col-24 > div > p {
  margin-bottom: 0px !important;
  line-height: 2.5; }

.ticket-chat-page-wrapper .ant-form-item-label > label {
  color: #000 !important;
  font-weight: bold; }

.ticket-chat-page-wrapper .ant-form-item-label, .ant-form-item-control {
  line-height: 20px !important; }

.ticket-chat-page-wrapper .ant-form-item-label {
  text-align: left !important; }

.ticket-chat-page-wrapper .detail .ant-form-item {
  margin-bottom: 10px !important; }

.cticket-chat-page-wrapperhat .ant-select-selection {
  background-color: #fafafa;
  border: 0px solid #d9d9d9;
  border-top-width: 0px; }

.ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  border: 0px;
  background-color: transparent;
  line-height: 25px; }

::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: transparent; }

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent; }

::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: #ccc; }

.notecss {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 8px;
  border-radius: 8px;
  margin: 10px 0px; }

#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div:nth-child(1) > div > div > div > div > div > div > div > div > div > div > span {
  font-size: 12px !important; }

#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div:nth-child(1) > div > div > div > div > div.rn-alignItems-1oszu61.rn-borderTopStyle-1efd50x.rn-borderRightStyle-14skgim.rn-borderBottomStyle-rull8r.rn-borderLeftStyle-mm0ijv.rn-borderTopWidth-13yce4e.rn-borderRightWidth-fnigne.rn-borderBottomWidth-ndvcnb.rn-borderLeftWidth-gxnn5r.rn-boxSizing-deolkf.rn-display-6koalj.rn-flexShrink-1qe8dj5.rn-flexBasis-1mlwlqe.rn-flexDirection-eqz5dr.rn-marginTop-1mnahxq.rn-marginBottom-p1pxzi.rn-marginLeft-11wrixw.rn-marginRight-1kb76zh.rn-minHeight-ifefl9.rn-minWidth-bcqeeo.rn-paddingTop-wk8lta.rn-paddingRight-9aemit.rn-paddingBottom-1mdbw0j.rn-paddingLeft-gy4na3.rn-position-bnwqim.rn-zIndex-1lgpqti > div {
  cursor: default !important; }

#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div:nth-child(1) > div > div > div > div > div.rn-alignItems-1oszu61.rn-borderTopStyle-1efd50x.rn-borderRightStyle-14skgim.rn-borderBottomStyle-rull8r.rn-borderLeftStyle-mm0ijv.rn-borderTopWidth-13yce4e.rn-borderRightWidth-fnigne.rn-borderBottomWidth-ndvcnb.rn-borderLeftWidth-gxnn5r.rn-boxSizing-deolkf.rn-display-6koalj.rn-flexShrink-1qe8dj5.rn-flexBasis-1mlwlqe.rn-flexDirection-eqz5dr.rn-marginTop-1mnahxq.rn-marginRight-61z16t.rn-marginBottom-p1pxzi.rn-marginLeft-1jkjb.rn-minHeight-ifefl9.rn-minWidth-bcqeeo.rn-paddingTop-wk8lta.rn-paddingRight-9aemit.rn-paddingBottom-1mdbw0j.rn-paddingLeft-gy4na3.rn-position-bnwqim.rn-zIndex-1lgpqti > div {
  cursor: default !important; }

.menu-bar-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid #e1e1e1; }
  .menu-bar-bottom .ant-input {
    border-radius: 0px; }
  .menu-bar-bottom textarea.ant-input {
    min-height: 83px;
    resize: none; }
  .menu-bar-bottom .ant-input:focus {
    border: 0px !important;
    outline: 0;
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0); }

.upload-file-btn {
  font-size: 35px;
  padding: 10px 25px;
  border: none;
  color: #47C5EB !important; }

.upload-file-text {
  font-weight: 500; }

.upload-file-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%; }

.chat-register-card {
  background-color: #fff;
  width: 250px;
  padding-bottom: 15px;
  border-radius: 13px 13px 0px 0px;
  margin: -9px -9px 0 -9px; }
  .chat-register-card img {
    border-radius: 13px 13px 0 0; }

.chat-regis-text {
  text-align: center;
  padding: 10px 0; }
  .chat-regis-text p {
    margin: 0;
    font-size: 14px; }

.chat-regis-btn-container {
  text-align: center; }
  .chat-regis-btn-container .chat-regis-btn {
    background-color: #48c5eb;
    color: white;
    border: 1px solid #48c5eb;
    padding: 5px 20px;
    border-radius: 5px;
    width: 80%;
    font-size: 14px; }

@media only screen and (max-width: 414px) {
  .upload-file-container {
    flex-direction: column; } }

.img-card-chat {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 13px 13px 0px 0px;
  margin: -9px -9px 0 -9px; }
  .img-card-chat .first-sec {
    display: flex;
    flex-direction: row; }
    .img-card-chat .first-sec .left-card-chat {
      display: flex;
      flex-direction: column;
      padding: 10px; }
    .img-card-chat .first-sec .right-card-chat {
      display: flex;
      flex-direction: column;
      padding: 10px; }
      .img-card-chat .first-sec .right-card-chat .right-card-chat-header {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px; }
      .img-card-chat .first-sec .right-card-chat p {
        font-size: 12px;
        font-weight: 500; }
      .img-card-chat .first-sec .right-card-chat .iconlist {
        height: 8px;
        width: 8px;
        background-color: #ff9900;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px; }
  .img-card-chat .second-sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px; }
    .img-card-chat .second-sec .left-card-sec2 p {
      font-weight: 500;
      margin: 0; }
    .img-card-chat .second-sec .right-card-sec2 p {
      font-size: 14px;
      font-weight: 500;
      color: #48c5eb;
      margin: 0; }

.left-category {
  background-color: #f8f8f8; }
  .left-category .inside-category {
    padding: 20px !important; }

.right-category {
  background-color: #fff; }
  .right-category .inside-category {
    padding: 20px !important; }

.category-header-text {
  margin-bottom: 20px; }
  .category-header-text h3 {
    font-size: 18px; }

.modal-product-input .ant-modal-body {
  padding: 0px !important; }

.product-list-item {
  border: 0.5px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  border-radius: 5px; }

.searchbox {
  border: none !important;
  height: 40px;
  padding: 15px; }
  .searchbox input {
    border: none; }

.modal-list-file {
  overflow: overlay;
  height: 40vh; }

.modal-list-product {
  overflow: overlay;
  height: 48vh; }

@media only screen and (max-width: 576px) {
  .chat-register-card {
    width: 200px; } }

.image-upload-container {
  flex-flow: row wrap; }
  .image-upload-container .upload-button-wrapper {
    margin: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 200px;
    max-width: calc(50% - 10px);
    padding-top: 60px;
    height: 200px;
    border-radius: 10px;
    text-align: center;
    border: 1px solid #d9d9d9;
    cursor: pointer; }
    .image-upload-container .upload-button-wrapper input {
      margin-top: 10px; }
    .image-upload-container .upload-button-wrapper p {
      font-size: 18px; }
  .image-upload-container .view-image-wrapper {
    margin: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 200px;
    max-width: calc(50% - 10px);
    height: 200px;
    border-radius: 10px;
    text-align: center;
    border: 1px solid #d9d9d9;
    cursor: pointer; }
    .image-upload-container .view-image-wrapper img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 173px;
      object-fit: cover;
      border-radius: 10px 10px 0px 0px; }
    .image-upload-container .view-image-wrapper button {
      border: 0px solid #d9d9d9;
      border-top: 1px solid #d9d9d9;
      border-radius: 0px 0px 10px 10px; }

.image-swiper-home-page .image-upload-container {
  display: flex;
  margin-bottom: 100px; }
  .image-swiper-home-page .image-upload-container .preview-image-wrapper {
    width: 150px;
    height: 300px;
    margin: 5px; }
  .image-swiper-home-page .image-upload-container .upload-button-wrapper {
    padding-top: 0px;
    width: 150px;
    height: 75px; }
    .image-swiper-home-page .image-upload-container .upload-button-wrapper p {
      font-size: 14px; }
    .image-swiper-home-page .image-upload-container .upload-button-wrapper .anticon {
      font-size: 20px !important; }
  .image-swiper-home-page .image-upload-container .view-image-wrapper {
    width: 150px;
    height: 75px; }
    .image-swiper-home-page .image-upload-container .view-image-wrapper img {
      width: 150px !important;
      height: 75px !important;
      max-height: 48px !important;
      object-fit: contain;
      background: #f7f7f7; }

.image-swiper-get-started-page {
  padding-bottom: 30px;
  margin-bottom: 30px; }
  .image-swiper-get-started-page .image-upload-container {
    display: flex; }
    .image-swiper-get-started-page .image-upload-container .preview-image-wrapper {
      width: 150px;
      height: 300px;
      margin: 5px; }
    .image-swiper-get-started-page .image-upload-container .upload-button-wrapper {
      width: 150px;
      height: 300px;
      padding-top: 10px; }
      .image-swiper-get-started-page .image-upload-container .upload-button-wrapper .anticon {
        font-size: 20px !important; }
      .image-swiper-get-started-page .image-upload-container .upload-button-wrapper p {
        font-size: 14px; }
    .image-swiper-get-started-page .image-upload-container .view-image-wrapper {
      width: 150px;
      height: 300px; }
      .image-swiper-get-started-page .image-upload-container .view-image-wrapper img {
        width: 150px !important;
        height: 300px !important;
        max-height: 273px !important;
        object-fit: contain;
        background: #f7f7f7; }

.layout-setting-container .image-upload-container .upload-button-wrapper {
  max-width: 100%;
  margin: 0px !important; }

.layout-setting-container .image-upload-container .view-image-wrapper {
  max-width: 100%;
  margin: 0px !important; }

.modal-ticket {
  width: 1000px; }

.ant-modal-content {
  border-radius: 10px !important; }
  .ant-modal-content .ant-modal-header {
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    background: #f3f3f3; }
  .ant-modal-content .ant-modal-footer {
    border-radius: 10px; }
  .ant-modal-content .ant-modal-body > div > div > label {
    font-size: 16px;
    font-weight: 500; }

.chatroom-ticket {
  position: absolute;
  left: 0;
  background-color: #fff;
  z-index: 3;
  height: calc(100vh - 46px);
  top: 46px !important;
  width: 100%; }

.ticket-chat-page-wrapper {
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  position: relative; }
  .ticket-chat-page-wrapper .left-section-chat {
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ececec; }
    .ticket-chat-page-wrapper .left-section-chat .left-section-chat-top-box {
      border-bottom: 1px solid #ececec; }
    .ticket-chat-page-wrapper .left-section-chat .left-sec-chat-header {
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center; }
    .ticket-chat-page-wrapper .left-section-chat .left-sec-chat-list {
      overflow: overlay;
      height: calc(100vh - 99px); }
    .ticket-chat-page-wrapper .left-section-chat .ant-list-split .ant-list-item {
      border: none; }
  .ticket-chat-page-wrapper .center-section-chat {
    height: 100vh; }
    .ticket-chat-page-wrapper .center-section-chat .center-sec-chat-header {
      display: none; }
  .ticket-chat-page-wrapper .right-section-chat {
    height: 100vh;
    overflow: overlay;
    border-left: 1px solid #ececec; }
    .ticket-chat-page-wrapper .right-section-chat .ant-select-selection {
      background: #f7f7f7; }
    .ticket-chat-page-wrapper .right-section-chat .ant-input {
      border: 1px solid #f5f5f5; }
    .ticket-chat-page-wrapper .right-section-chat .detail {
      display: flex;
      flex-direction: column;
      padding: 10px;
      overflow: overlay; }
      .ticket-chat-page-wrapper .right-section-chat .detail .image-wrapper {
        display: flex;
        justify-content: center; }
        .ticket-chat-page-wrapper .right-section-chat .detail .image-wrapper img {
          border-radius: 50px; }
      .ticket-chat-page-wrapper .right-section-chat .detail p {
        text-align: center;
        margin: 20px;
        font-weight: 500; }
      .ticket-chat-page-wrapper .right-section-chat .detail .divider {
        border-top: 1px solid #ececec;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%; }
      .ticket-chat-page-wrapper .right-section-chat .detail .edit-button {
        background-color: #1fd963;
        border: none;
        padding: 10px;
        color: #fff;
        font-weight: 500;
        border-radius: 7px;
        width: 200px;
        cursor: pointer; }
    .ticket-chat-page-wrapper .right-section-chat .btn-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: absolute;
      margin-bottom: 10px;
      right: 65px; }
    .ticket-chat-page-wrapper .right-section-chat .ant-tabs-nav .ant-tabs-tab-active {
      color: #48c5eb; }
    .ticket-chat-page-wrapper .right-section-chat .ant-tabs-bar {
      margin-bottom: 0px; }
    .ticket-chat-page-wrapper .right-section-chat .ant-tabs-ink-bar {
      background-color: #48c5eb;
      height: 3px; }
    .ticket-chat-page-wrapper .right-section-chat .tag-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
    .ticket-chat-page-wrapper .right-section-chat .tag-section {
      text-align: center; }
    .ticket-chat-page-wrapper .right-section-chat .tag-btn {
      border: 1px solid #48c5eb;
      color: #48c5eb;
      cursor: pointer;
      padding: 3px 15px;
      margin: 3px;
      height: 100%; }
    .ticket-chat-page-wrapper .right-section-chat .tag-list {
      padding: 3px 15px;
      margin: 3px; }
    .ticket-chat-page-wrapper .right-section-chat .right-tab-css {
      height: calc(100vh - 47px);
      overflow: overlay; }
      .ticket-chat-page-wrapper .right-section-chat .right-tab-css .ant-form-item-label > label {
        color: #8c8c8c !important; }
      .ticket-chat-page-wrapper .right-section-chat .right-tab-css .note-container {
        background-color: #48c5eb14;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px; }
        .ticket-chat-page-wrapper .right-section-chat .right-tab-css .note-container .note-time {
          font-size: 12px; }
        .ticket-chat-page-wrapper .right-section-chat .right-tab-css .note-container .note-text1 {
          font-size: 12px; }
        .ticket-chat-page-wrapper .right-section-chat .right-tab-css .note-container .note-text2 {
          font-size: 16px;
          color: #00abdf; }

.chatlist-content-wrapper {
  display: flex;
  flex-direction: column; }
  .chatlist-content-wrapper .item-wrapper {
    display: grid;
    grid-template-columns: 50px 1fr 90px; }
    .chatlist-content-wrapper .item-wrapper .image-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: start; }
    .chatlist-content-wrapper .item-wrapper .text-wrapper {
      display: flex;
      flex-direction: column;
      padding-left: 10px; }
      .chatlist-content-wrapper .item-wrapper .text-wrapper .name {
        margin: 0;
        padding-bottom: 2;
        font-weight: 500;
        color: #010101;
        font-size: 13px; }
      .chatlist-content-wrapper .item-wrapper .text-wrapper .message {
        margin: 0;
        font-weight: 300;
        color: #7a8187;
        font-size: 13px; }
      .chatlist-content-wrapper .item-wrapper .text-wrapper .message-read {
        margin: 0;
        font-weight: 700;
        color: #000;
        font-size: 13px; }
    .chatlist-content-wrapper .item-wrapper .time-wrapper {
      display: flex;
      flex-direction: column; }
      .chatlist-content-wrapper .item-wrapper .time-wrapper .datetime {
        margin: 0;
        text-align: center;
        font-weight: 300;
        color: #adb5bd;
        font-size: 12px; }

.img-upp {
  width: 100%; }

.ant-tabs-nav .ant-tabs-tab-active {
  color: #48c5eb;
  font-weight: 500; }

.ant-tabs-ink-bar {
  color: #48c5eb;
  height: 7px; }

.ticket-product {
  display: flex;
  flex-direction: column;
  margin: 15px 0; }
  .ticket-product img {
    display: flex;
    align-self: center; }
  .ticket-product p {
    font-size: 18px; }
  .ticket-product .list-box {
    display: flex;
    flex-direction: row; }
  .ticket-product .icon-list {
    height: 8px;
    width: 8px;
    background-color: #ff9900;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    margin-top: 7px; }

.ticket-attachment {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
  grid-row-gap: 5px;
  grid-column-gap: 5px; }

.ticket-attachment-video {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  grid-row-gap: 5px;
  grid-column-gap: 5px; }

.attachment-title {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
  margin-left: 20px; }

.ticket-attachment-file {
  display: grid;
  grid-template-columns: 1fr;
  padding: 10px;
  grid-row-gap: 5px;
  grid-column-gap: 5px; }

@media only screen and (max-width: 576px) {
  .ticket-chat-page-wrapper .left-section-chat {
    display: none; }
  .ticket-chat-page-wrapper .right-section-chat {
    position: absolute;
    right: 0;
    transition: 1s ease-in-out;
    width: 90%;
    z-index: 1; }
  .ticket-chat-page-wrapper {
    grid-template-columns: 100%; }
  .ticket-chat-page-wrapper .center-section-chat {
    height: calc(90vh - 90px); }
  .ticket-chat-page-wrapper .center-section-chat .center-sec-chat-header {
    display: block;
    padding: 10px; }
  .img-card-chat .first-sec {
    flex-direction: column; } }

@media only screen and (min-width: 576px) {
  .ticket-chat-page-wrapper .left-section-chat {
    display: none; }
  .ticket-chat-page-wrapper .right-section-chat {
    display: none; }
  .ticket-chat-page-wrapper {
    grid-template-columns: 100%; }
  .ticket-chat-page-wrapper .center-section-chat {
    height: calc(90vh - 90px); }
  .ticket-chat-page-wrapper .center-section-chat .center-sec-chat-header {
    display: block;
    padding: 10px; } }

@media only screen and (min-width: 768px) {
  .ticket-chat-page-wrapper .left-section-chat {
    display: none; }
  .ticket-chat-page-wrapper .right-section-chat {
    display: none; }
  .ticket-chat-page-wrapper {
    grid-template-columns: 100%; }
  .ticket-chat-page-wrapper .center-section-chat {
    height: calc(100vh - 47px);
    display: flex;
    flex-direction: column; }
  .ticket-chat-page-wrapper .center-section-chat .center-sec-chat-header {
    display: block;
    padding: 10px; }
  .img-card-chat .first-sec {
    flex-direction: row; } }

@media only screen and (min-width: 1024px) {
  .ticket-chat-page-wrapper {
    grid-template-columns: 300px 1fr; }
  .ticket-chat-page-wrapper .left-section-chat {
    display: flex;
    flex-direction: column; }
  .ticket-chat-page-wrapper .right-section-chat {
    display: none; }
  .ticket-chat-page-wrapper .center-section-chat .center-sec-chat-header {
    display: none;
    padding: 10px; } }

@media only screen and (min-width: 1200px) {
  .ticket-chat-page-wrapper {
    grid-template-columns: 300px 1fr 300px; }
  .ticket-chat-page-wrapper .right-section-chat {
    display: block; } }

.left-section-chat .ant-btn span {
  font-size: 14px; }


.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #f9f9f9 !important;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #f9f9f9 !important;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.forgetpassword-page-wrapper {
  display: flex;
  flex-direction: row; }
  .forgetpassword-page-wrapper .left-colunm-wrapper {
    background-color: #65D2F3;
    background-image: url(/static/media/loginleftbg.b2133727.png);
    min-height: 100vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 100px 5% 50px 5%; }
    @media only screen and (max-width: 834px) {
      .forgetpassword-page-wrapper .left-colunm-wrapper {
        display: none; } }
    .forgetpassword-page-wrapper .left-colunm-wrapper .logo {
      display: flex;
      position: absolute;
      top: 8%; }
    .forgetpassword-page-wrapper .left-colunm-wrapper .left-pic {
      margin: 0 auto; }
    .forgetpassword-page-wrapper .left-colunm-wrapper .title {
      margin: 20px 0;
      font-size: 64px;
      font-weight: 500; }
    .forgetpassword-page-wrapper .left-colunm-wrapper .title-forgot {
      margin: 20px 0;
      font-size: 40px;
      font-weight: 200; }
    .forgetpassword-page-wrapper .left-colunm-wrapper .border-title {
      border-bottom: 15px solid #48c5eb;
      height: 10px;
      width: 150px; }
    .forgetpassword-page-wrapper .left-colunm-wrapper .sub-title {
      font-size: 34px;
      line-height: 2.5rem;
      text-align: center;
      margin: 0;
      color: #fff; }
    .forgetpassword-page-wrapper .left-colunm-wrapper .sub-title-forgot {
      font-size: 22px;
      line-height: 2rem;
      margin: 0; }
      .forgetpassword-page-wrapper .left-colunm-wrapper .sub-title-forgot span {
        color: #48c5eb; }
    .forgetpassword-page-wrapper .left-colunm-wrapper .sub-title-2 {
      font-size: 24px;
      line-height: 2.5rem;
      margin: 0; }
    .forgetpassword-page-wrapper .left-colunm-wrapper .sub-title-2-forgot {
      font-size: 18px;
      line-height: 2rem;
      margin: 0; }
    .forgetpassword-page-wrapper .left-colunm-wrapper .circle {
      height: 10px;
      width: 10px;
      background-color: #000;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px; }
    .forgetpassword-page-wrapper .left-colunm-wrapper .devider-forgot {
      height: 20px; }
    .forgetpassword-page-wrapper .left-colunm-wrapper .login-footer {
      position: absolute;
      bottom: 40px;
      text-align: center;
      color: #fff; }
  .forgetpassword-page-wrapper .right-colunm-wrapper {
    background-color: #fcfcfc;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; }
    .forgetpassword-page-wrapper .right-colunm-wrapper .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .forgetpassword-page-wrapper .right-colunm-wrapper .container .logo {
        display: none; }
      .forgetpassword-page-wrapper .right-colunm-wrapper .container .title {
        margin: 10px 0;
        font-size: 55px;
        font-weight: 400; }
        .forgetpassword-page-wrapper .right-colunm-wrapper .container .title .title-bold {
          font-weight: 500; }
      .forgetpassword-page-wrapper .right-colunm-wrapper .container .sub-title {
        font-size: 18px;
        line-height: 2.5rem;
        font-weight: 400;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 10px;
        text-align: center; }
      .forgetpassword-page-wrapper .right-colunm-wrapper .container .logo {
        text-align: center;
        width: 100%;
        margin-bottom: 50px; }
        .forgetpassword-page-wrapper .right-colunm-wrapper .container .logo img {
          max-width: 100%; }
      .forgetpassword-page-wrapper .right-colunm-wrapper .container .input-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 450px;
        padding: 20px; }
        .forgetpassword-page-wrapper .right-colunm-wrapper .container .input-group .input-login {
          margin-bottom: 10px; }
        .forgetpassword-page-wrapper .right-colunm-wrapper .container .input-group .input-login::-webkit-input-placeholder {
          color: #eaeaea; }
        .forgetpassword-page-wrapper .right-colunm-wrapper .container .input-group .input-login::placeholder {
          color: #eaeaea; }
        .forgetpassword-page-wrapper .right-colunm-wrapper .container .input-group .ant-input-affix-wrapper {
          font-size: 20px !important; }
        .forgetpassword-page-wrapper .right-colunm-wrapper .container .input-group .ant-input-lg {
          height: 55px;
          font-size: 20px !important;
          padding-left: 40px !important; }
        .forgetpassword-page-wrapper .right-colunm-wrapper .container .input-group button {
          padding: 15px 10px !important;
          margin: 20px 0;
          border-radius: 4px !important;
          font-size: 18px !important;
          border: none;
          background: #00c4ff;
          background: linear-gradient(0deg, #00c4ff 0%, #3fc5ed 100%);
          color: #fff;
          font-weight: 500; }

@media only screen and (min-width: 1440px) {
  .input-group {
    width: 60%; } }

@media only screen and (max-width: 1024px) {
  .forgetpassword-page-wrapper .left-colunm-wrapper {
    justify-content: center;
    padding: 50px; }
  .forgetpassword-page-wrapper .left-colunm-wrapper .login-footer {
    margin-right: 50px; } }

@media only screen and (max-width: 834px) {
  .left-colunm-wrapper {
    display: none !important; }
  .right-colunm-wrapper {
    width: 100% !important; }
    .right-colunm-wrapper .logo {
      display: block !important; } }

@media only screen and (max-width: 414px) {
  .forgetpassword-page-wrapper .right-colunm-wrapper .container .input-group {
    width: 100%; } }

.login-page-wrapper {
  display: flex;
  flex-direction: row; }
  .login-page-wrapper .left-colunm-signup-wrapper {
    background-color: #65D2F3;
    background-image: url(/static/media/loginleftbg.b2133727.png);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 100px 0 50px 5%; }
    @media only screen and (max-width: 834px) {
      .login-page-wrapper .left-colunm-signup-wrapper {
        display: none; } }
    .login-page-wrapper .left-colunm-signup-wrapper .logo {
      position: absolute;
      top: 8%; }
    .login-page-wrapper .left-colunm-signup-wrapper .title {
      margin: 10px 0;
      font-size: 70px;
      font-weight: 400; }
    .login-page-wrapper .left-colunm-signup-wrapper .border-title {
      border-bottom: 12px solid #48c5eb;
      margin-left: 12px;
      width: 100px;
      margin-bottom: 30 px; }
    .login-page-wrapper .left-colunm-signup-wrapper .sub-title {
      font-size: 32px;
      line-height: 50px;
      font-weight: 400;
      margin: 0;
      color: #fff; }
    .login-page-wrapper .left-colunm-signup-wrapper .sub-title-2 {
      font-size: 42px;
      line-height: 50px;
      font-weight: 700;
      margin: 0;
      color: #fff; }
    .login-page-wrapper .left-colunm-signup-wrapper .sub-title-3 {
      font-size: 18px;
      line-height: 2.5rem;
      font-weight: 400;
      margin: 0;
      margin-top: 70px;
      color: #fff; }
    .login-page-wrapper .left-colunm-signup-wrapper .signup-button {
      background-color: #ffffff;
      border-radius: 20px;
      margin-top: 20px;
      padding: 10px 20px;
      max-width: 200px;
      font-size: 18px;
      text-align: center;
      font-weight: 500;
      transition: 0.3s; }
      .login-page-wrapper .left-colunm-signup-wrapper .signup-button:hover {
        background-color: #e4e4e4; }
      .login-page-wrapper .left-colunm-signup-wrapper .signup-button p {
        margin: 0; }
    .login-page-wrapper .left-colunm-signup-wrapper .login-footer {
      position: absolute;
      bottom: 8%;
      text-align: left;
      color: #fff; }
  .login-page-wrapper .right-colunm-signup-wrapper {
    background-color: #fcfcfc;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; }
    .login-page-wrapper .right-colunm-signup-wrapper .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .login-page-wrapper .right-colunm-signup-wrapper .container .logo {
        display: none; }
      .login-page-wrapper .right-colunm-signup-wrapper .container .title {
        margin: 10px 0;
        font-size: 55px;
        font-weight: 400; }
        .login-page-wrapper .right-colunm-signup-wrapper .container .title .title-bold {
          font-weight: 500; }
      .login-page-wrapper .right-colunm-signup-wrapper .container .sub-title {
        font-size: 18px;
        line-height: 2.5rem;
        font-weight: 400;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 10px;
        text-align: center; }
      .login-page-wrapper .right-colunm-signup-wrapper .container .logo {
        text-align: center;
        width: 100%;
        margin-bottom: 50px; }
        .login-page-wrapper .right-colunm-signup-wrapper .container .logo img {
          max-width: 100%; }
      .login-page-wrapper .right-colunm-signup-wrapper .container .input-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 450px;
        padding: 20px; }
        .login-page-wrapper .right-colunm-signup-wrapper .container .input-group .input-login {
          margin-bottom: 10px; }
        .login-page-wrapper .right-colunm-signup-wrapper .container .input-group .input-login::-webkit-input-placeholder {
          color: #eaeaea; }
        .login-page-wrapper .right-colunm-signup-wrapper .container .input-group .input-login::placeholder {
          color: #eaeaea; }
        .login-page-wrapper .right-colunm-signup-wrapper .container .input-group .ant-input-affix-wrapper {
          font-size: 20px !important; }
        .login-page-wrapper .right-colunm-signup-wrapper .container .input-group .ant-input-lg {
          height: 55px;
          font-size: 20px !important;
          padding-left: 40px !important; }
        .login-page-wrapper .right-colunm-signup-wrapper .container .input-group button {
          padding: 15px 10px !important;
          margin: 20px 0;
          border-radius: 4px !important;
          font-size: 18px !important;
          border: none;
          background: #00c4ff;
          background: linear-gradient(0deg, #00c4ff 0%, #3fc5ed 100%);
          color: #fff;
          font-weight: 500; }

@media only screen and (min-width: 1440px) {
  .login-page-wrapper .left-colunm-wrapper {
    padding: 150px 0 50px 100px; }
  .input-group {
    width: 60%; } }

@media only screen and (max-width: 1024px) {
  .login-page-wrapper .left-colunm-wrapper {
    justify-content: center;
    padding: 50px; }
  .login-page-wrapper .left-colunm-wrapper .login-footer {
    margin-right: 50px; } }

@media only screen and (max-width: 834px) {
  .left-colunm-wrapper {
    display: none !important; }
  .right-colunm-wrapper {
    width: 100% !important; }
    .right-colunm-wrapper .logo {
      display: block !important; } }

@media only screen and (max-width: 414px) {
  .login-page-wrapper .right-colunm-wrapper .container .input-group {
    width: 100%; } }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 80%;
  background-color: #ffffff;
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.card-itembox {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px; }
  .card-itembox .itembox-header {
    background-color: #48c5eb;
    color: #fff;
    padding: 4px 10px;
    border-radius: 3px; }

.from-border-box {
  border: 0.5px solid #e1e1e1;
  border-radius: 10px; }

.header-row {
  display: flex;
  flex-direction: row;
  align-items: end; }
  .header-row h3 {
    font-size: 15px; }
  .header-row .ant-checkbox-inner {
    border-radius: 50%; }
  .header-row .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #37a800;
    border-color: #37a800;
    border-radius: 50%; }

.row-box {
  border-bottom: 0.5px solid #e1e1e1;
  padding: 15px; }

.row-box-modal {
  padding: 15px; }

@media only screen and (max-width: 576px) {
  .header-card {
    align-items: center;
    justify-content: center;
    flex-direction: column; } }

.video-upload-container {
  flex-flow: row wrap; }
  .video-upload-container .upload-button-wrapper {
    margin: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 200px;
    max-width: calc(50% - 10px);
    padding-top: 60px;
    height: 200px;
    border-radius: 10px;
    text-align: center;
    border: 1px solid #d9d9d9;
    cursor: pointer; }
    .video-upload-container .upload-button-wrapper input {
      margin-top: 10px; }
    .video-upload-container .upload-button-wrapper p {
      font-size: 18px; }
  .video-upload-container .view-image-wrapper {
    margin: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 200px;
    max-width: calc(50% - 10px);
    max-height: 200px;
    border-radius: 10px;
    text-align: center;
    border: 1px solid #d9d9d9;
    cursor: pointer; }
    .video-upload-container .view-image-wrapper img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 173px;
      object-fit: cover;
      border-radius: 10px 10px 0px 0px; }
    .video-upload-container .view-image-wrapper button {
      border: 0px solid #d9d9d9;
      border-top: 1px solid #d9d9d9;
      border-radius: 0px 0px 10px 10px; }

.video-swiper-home-page .video-upload-container {
  display: flex;
  margin-bottom: 100px; }
  .video-swiper-home-page .video-upload-container .preview-video-wrapper {
    width: 150px;
    height: 300px;
    margin: 5px; }
  .video-swiper-home-page .video-upload-container .upload-button-wrapper {
    padding-top: 0px;
    width: 150px;
    height: 75px; }
    .video-swiper-home-page .video-upload-container .upload-button-wrapper p {
      font-size: 14px; }
    .video-swiper-home-page .video-upload-container .upload-button-wrapper .anticon {
      font-size: 20px !important; }
  .video-swiper-home-page .video-upload-container .view-image-wrapper {
    width: 230px;
    height: 160px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.image-upload-container .ant-row-flex {
  flex-flow: unset; }

@media only screen and (max-width: 576px) {
  .header-card {
    align-items: center;
    justify-content: center;
    flex-direction: column; } }

.product-sku-table .ant-table-row {
  height: 50px !important; }

.product-sku-column-action {
  display: flex;
  justify-content: flex-end;
  grid-gap: 12px !important;
  gap: 12px !important;
  padding: 0 12px !important; }

.product-sku-modal-align-left .ant-form-item-label {
  text-align: left; }

.product-sku-table .ant-table-body .ant-table-tbody .ant-table-row-cell-break-word {
  height: auto !important; }

.block {
  display: block !important; }

.card-align-left {
  text-align: left !important; }

.w-100 .ant-select {
  width: 100%; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.image-upload-container .ant-row-flex {
  flex-flow: unset; }

@media only screen and (max-width: 576px) {
  .header-card {
    align-items: center;
    justify-content: center;
    flex-direction: column; } }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

@media only screen and (max-width: 576px) {
  .header-card {
    align-items: center;
    justify-content: center;
    flex-direction: column; } }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.order-address-container-wrapper {
  margin-bottom: 50px; }

.order-product-container-wrapper {
  margin-bottom: 50px; }
  .order-product-container-wrapper .action-button-wrapper {
    padding: 10px 15px; }
    .order-product-container-wrapper .action-button-wrapper .title-header {
      font-size: 20px;
      font-weight: bold;
      margin: 0px; }
    .order-product-container-wrapper .action-button-wrapper .create-button {
      color: #ffffff;
      background-color: #37ba00; }
    .order-product-container-wrapper .action-button-wrapper .create-button:hover {
      color: #ffffff;
      border-color: #34a803; }

.truncate-2-lines {
  display: -webkit-box;
  /* Establish a flex container */
  -webkit-box-orient: vertical;
  /* Set the box orientation to vertical */
  -webkit-line-clamp: 2;
  /* Limit to 2 lines */
  overflow: hidden;
  /* Hide the overflowing text */ }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.ant-input-group.ant-input-group-compact {
  display: flex !important; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

@media only screen and (max-width: 576px) {
  .header-card > div:nth-child(1) > span {
    font-size: 18px !important; } }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.address-customer-container-wrapper {
  margin-bottom: 50px; }
  .address-customer-container-wrapper .action-button-wrapper {
    padding: 10px 15px; }
    .address-customer-container-wrapper .action-button-wrapper .title-header {
      font-size: 20px;
      font-weight: bold;
      margin: 0px; }
    .address-customer-container-wrapper .action-button-wrapper .create-button {
      color: #ffffff;
      background-color: #37ba00; }
    .address-customer-container-wrapper .action-button-wrapper .create-button:hover {
      color: #ffffff;
      border-color: #34a803; }

.notification-customer-container-wrapper {
  margin-bottom: 50px; }
  .notification-customer-container-wrapper .action-button-wrapper {
    padding: 10px 15px; }
    .notification-customer-container-wrapper .action-button-wrapper .title-header {
      font-size: 20px;
      font-weight: bold;
      margin: 0px; }
    .notification-customer-container-wrapper .action-button-wrapper .create-button {
      color: #ffffff;
      background-color: #37ba00; }
    .notification-customer-container-wrapper .action-button-wrapper .create-button:hover {
      color: #ffffff;
      border-color: #34a803; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card-register {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 20px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }
  .cardstyle .ant-card-body .ant-form {
    max-width: 850px;
    margin: 0 auto; }
    .cardstyle .ant-card-body .ant-form .ant-table-tbody td:not(.original-style-input-table) {
      padding-left: 16px; }

.cardstyle.mw-l .ant-card-body .ant-form {
  max-width: 1200px; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.address-customer-container-wrapper {
  margin-bottom: 50px; }
  .address-customer-container-wrapper .action-button-wrapper {
    padding: 10px 15px; }
    .address-customer-container-wrapper .action-button-wrapper .title-header {
      font-size: 20px;
      font-weight: bold;
      margin: 0px; }
    .address-customer-container-wrapper .action-button-wrapper .create-button {
      color: #ffffff;
      background-color: #37ba00; }
    .address-customer-container-wrapper .action-button-wrapper .create-button:hover {
      color: #ffffff;
      border-color: #34a803; }

.notification-customer-container-wrapper {
  margin-bottom: 50px; }
  .notification-customer-container-wrapper .action-button-wrapper {
    padding: 10px 15px; }
    .notification-customer-container-wrapper .action-button-wrapper .title-header {
      font-size: 20px;
      font-weight: bold;
      margin: 0px; }
    .notification-customer-container-wrapper .action-button-wrapper .create-button {
      color: #ffffff;
      background-color: #37ba00; }
    .notification-customer-container-wrapper .action-button-wrapper .create-button:hover {
      color: #ffffff;
      border-color: #34a803; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.address-customer-container-wrapper {
  margin-bottom: 50px; }
  .address-customer-container-wrapper .action-button-wrapper {
    padding: 10px 15px; }
    .address-customer-container-wrapper .action-button-wrapper .title-header {
      font-size: 20px;
      font-weight: bold;
      margin: 0px; }
    .address-customer-container-wrapper .action-button-wrapper .create-button {
      color: #ffffff;
      background-color: #37ba00; }
    .address-customer-container-wrapper .action-button-wrapper .create-button:hover {
      color: #ffffff;
      border-color: #34a803; }

.notification-customer-container-wrapper {
  margin-bottom: 50px; }
  .notification-customer-container-wrapper .action-button-wrapper {
    padding: 10px 15px; }
    .notification-customer-container-wrapper .action-button-wrapper .title-header {
      font-size: 20px;
      font-weight: bold;
      margin: 0px; }
    .notification-customer-container-wrapper .action-button-wrapper .create-button {
      color: #ffffff;
      background-color: #37ba00; }
    .notification-customer-container-wrapper .action-button-wrapper .create-button:hover {
      color: #ffffff;
      border-color: #34a803; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.package-container {
  text-align: right; }
  .package-container .create-button {
    color: #ffffff;
    background-color: #37ba00; }
  .package-container .create-button:hover {
    color: #ffffff;
    border-color: #34a803; }

.review-container .create-button {
  color: #ffffff;
  background-color: #37ba00; }

.review-container .create-button:hover {
  color: #ffffff;
  border-color: #34a803; }

.upload-id-card, .upload-file-compant {
  color: #ddd;
  background: #fff;
  width: 400px;
  height: 33px;
  border-radius: 4px;
  border: 1px #ddd solid;
  overflow: hidden;
  margin-bottom: 10px;
  display: flex; }

.title-table-comment {
  text-align: center; }

.color-item-wrapper-theme {
  padding: 0px; }
  .color-item-wrapper-theme .title {
    margin: 0px;
    font-size: 18px;
    text-align: left; }
  .color-item-wrapper-theme div {
    display: flex;
    border: 1px solid #dadada;
    border-radius: 5px; }
    .color-item-wrapper-theme div .color {
      width: 80px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #dadada; }
    .color-item-wrapper-theme div .code {
      margin: 0px;
      padding: 6px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #f9f9f9 !important;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card-theme {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  padding: 20px; }

.color-item-wrapper {
  padding: 20px; }
  .color-item-wrapper .title {
    margin: 0px;
    padding: 5px;
    font-size: 18px; }
  .color-item-wrapper div {
    display: flex;
    border: 1px solid #F4F4F4;
    border-radius: 3px; }
    .color-item-wrapper div .color {
      width: 80px;
      height: 40px;
      border-radius: 3px;
      border: 1px solid #F4F4F4; }
    .color-item-wrapper div .code {
      margin: 0px;
      padding: 6px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #f9f9f9 !important;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card-swiper {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 20px; }

.header-card-setting {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 20px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #fff !important;
  height: calc(95% - 0vh);
  margin: 0; }
  .cardstyle .package-header {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 30px; }
    .cardstyle .package-header span {
      color: #47C5EB; }
  .cardstyle .progress-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px; }
  .cardstyle .progress-box {
    padding: 20px;
    border-radius: 10px;
    position: relative; }
    .cardstyle .progress-box .progress-title {
      display: flex;
      justify-content: center; }
      .cardstyle .progress-box .progress-title h3 {
        font-size: 23px;
        margin-bottom: 24px;
        color: #363636; }
    .cardstyle .progress-box .custom-progress {
      display: flex;
      flex-direction: row; }
      .cardstyle .progress-box .custom-progress .first-p {
        position: absolute;
        z-index: 3;
        margin-left: 20px;
        margin-top: 3px;
        color: #fff; }
      .cardstyle .progress-box .custom-progress .progrss-bar {
        position: relative; }
      .cardstyle .progress-box .custom-progress .last-p {
        position: absolute;
        z-index: 3;
        margin-right: 50px;
        margin-top: 3px;
        right: 0; }
    .cardstyle .progress-box .progress-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px; }
      .cardstyle .progress-box .progress-info .divider {
        border-left: 3px;
        height: 35px; }
      .cardstyle .progress-box .progress-info .progress-info-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center; }
        .cardstyle .progress-box .progress-info .progress-info-item h3 {
          margin: 0;
          color: #363636; }
  .cardstyle .setting-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #f9f9f9;
    align-items: center;
    padding: 30px;
    border-radius: 5px; }
    .cardstyle .setting-container .setting-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 10px; }
      .cardstyle .setting-container .setting-item p {
        margin-bottom: 0;
        margin-right: 10px;
        width: 131px; }
  .cardstyle .layout-container {
    display: grid;
    grid-template-columns: 300px 2fr;
    padding: 10px;
    margin-bottom: 20px; }
    .cardstyle .layout-container .right-layout {
      display: grid;
      grid-template-columns: 1fr 1fr; }
      .cardstyle .layout-container .right-layout .layout-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .cardstyle .layout-container .right-layout .layout-item .ant-checkbox-inner {
          border-radius: 50%; }
        .cardstyle .layout-container .right-layout .layout-item .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #48c5eb;
          border-color: #48c5eb;
          border-radius: 50%; }
    .cardstyle .layout-container .left-layout {
      display: grid;
      grid-template-columns: 1fr; }
      .cardstyle .layout-container .left-layout .item-menu-box .menu-box-item {
        display: flex;
        flex-direction: row;
        padding: 15px;
        background-color: #48c5eb;
        border-radius: 5px;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer; }
        .cardstyle .layout-container .left-layout .item-menu-box .menu-box-item img {
          width: 20px;
          height: 20px;
          margin-right: 10px; }
        .cardstyle .layout-container .left-layout .item-menu-box .menu-box-item p {
          margin: 0px;
          color: #fff;
          font-size: 16px; }
  .cardstyle .layout-setting-container {
    display: flex;
    flex-direction: column; }
    .cardstyle .layout-setting-container .layout-setting-item {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 10px;
      padding: 20px 20px 10px;
      background-color: #f9f9f9;
      border-radius: 5px; }

.layout-setting-item .ant-row {
  margin-bottom: 2px !important; }

.color-item-wrapper {
  padding: 20px; }
  .color-item-wrapper .title {
    margin: 0px;
    padding: 5px;
    font-size: 18px; }
  .color-item-wrapper div {
    display: flex;
    border: 1px solid #F4F4F4;
    border-radius: 3px; }
    .color-item-wrapper div .color {
      width: 80px;
      height: 40px;
      border-radius: 0px;
      border: 0px;
      border-right: 1px solid #F4F4F4; }
    .color-item-wrapper div .code {
      margin: 0px;
      padding: 6px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px; }

.layout-wrapper {
  text-align: center;
  padding: 5px; }
  .layout-wrapper .ant-row {
    color: #000000; }
    .layout-wrapper .ant-row .ant-col {
      vertical-align: middle;
      line-height: 50px;
      background-color: #ccf4ff;
      border: 1px solid #ffffff; }

@media only screen and (max-width: 576px) {
  .cardstyle .progress-container {
    grid-template-columns: 1fr;
    grid-gap: 10px; }
  .cardstyle .setting-container {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start; }
  .cardstyle .layout-container {
    grid-template-columns: 1fr; }
  .cardstyle .layout-container .right-layout {
    grid-template-columns: 1fr; }
  .cardstyle .layout-setting-container .layout-setting-item {
    flex-direction: column; } }

@media only screen and (min-width: 576px) {
  .cardstyle .progress-container {
    grid-template-columns: 1fr;
    grid-gap: 10px; }
  .cardstyle .setting-container {
    flex-wrap: wrap; }
  .cardstyle .layout-container {
    grid-template-columns: 1fr; } }

@media only screen and (min-width: 768px) {
  .cardstyle .progress-container {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px; }
  .cardstyle .setting-container {
    flex-wrap: wrap; }
  .cardstyle .layout-container {
    grid-template-columns: 1fr; }
  .cardstyle .layout-setting-container .layout-setting-item {
    flex-direction: column; } }

@media only screen and (min-width: 1024px) {
  .cardstyle .layout-container {
    grid-template-columns: 300px 2fr; }
  .cardstyle .layout-setting-container .layout-setting-item {
    flex-direction: row; } }

.color-item-wrapper {
  padding: 20px; }
  .color-item-wrapper .title {
    margin: 0px;
    font-size: 18px;
    text-align: left; }
  .color-item-wrapper div {
    display: flex;
    border: 1px solid #dadada;
    border-radius: 5px; }
    .color-item-wrapper div .color {
      width: 80px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #dadada; }
    .color-item-wrapper div .code {
      margin: 0px;
      padding: 6px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #f9f9f9 !important;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card-theme {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  padding: 20px; }

.mobile-screen-content-wrapper {
  display: flex;
  overflow: scroll; }
  .mobile-screen-content-wrapper .item-mobile-screen-wrapper {
    display: flex; }
    .mobile-screen-content-wrapper .item-mobile-screen-wrapper .item-mobile-screen-image-wrapper {
      padding: 3px; }
    .mobile-screen-content-wrapper .item-mobile-screen-wrapper .item-mobile-screen-image {
      border-radius: 5px;
      width: 200px;
      height: auto; }

.header-card-setting {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 20px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #fff !important;
  height: calc(95% - 0vh);
  margin: 0; }
  .cardstyle .package-header {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 30px; }
    .cardstyle .package-header span {
      color: #47C5EB; }
  .cardstyle .progress-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px; }
  .cardstyle .progress-box {
    padding: 20px;
    border-radius: 10px;
    position: relative; }
    .cardstyle .progress-box .progress-title {
      display: flex;
      justify-content: center; }
      .cardstyle .progress-box .progress-title h3 {
        font-size: 23px;
        margin-bottom: 24px;
        color: #363636; }
    .cardstyle .progress-box .custom-progress {
      display: flex;
      flex-direction: row; }
      .cardstyle .progress-box .custom-progress .first-p {
        position: absolute;
        z-index: 3;
        margin-left: 20px;
        margin-top: 3px;
        color: #fff; }
      .cardstyle .progress-box .custom-progress .progrss-bar {
        position: relative; }
      .cardstyle .progress-box .custom-progress .last-p {
        position: absolute;
        z-index: 3;
        margin-right: 50px;
        margin-top: 3px;
        right: 0; }
    .cardstyle .progress-box .progress-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px; }
      .cardstyle .progress-box .progress-info .divider {
        border-left: 3px;
        height: 35px; }
      .cardstyle .progress-box .progress-info .progress-info-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center; }
        .cardstyle .progress-box .progress-info .progress-info-item h3 {
          margin: 0;
          color: #363636; }
  .cardstyle .setting-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #f9f9f9;
    align-items: center;
    padding: 30px;
    border-radius: 5px; }
    .cardstyle .setting-container .setting-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 10px; }
      .cardstyle .setting-container .setting-item p {
        margin-bottom: 0;
        margin-right: 10px;
        width: 131px; }
  .cardstyle .layout-container {
    display: grid;
    grid-template-columns: 300px 2fr;
    padding: 10px;
    margin-bottom: 20px; }
    .cardstyle .layout-container .right-layout {
      display: grid;
      grid-template-columns: 1fr 1fr; }
      .cardstyle .layout-container .right-layout .layout-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .cardstyle .layout-container .right-layout .layout-item .ant-checkbox-inner {
          border-radius: 50%; }
        .cardstyle .layout-container .right-layout .layout-item .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #48c5eb;
          border-color: #48c5eb;
          border-radius: 50%; }
    .cardstyle .layout-container .left-layout {
      display: grid;
      grid-template-columns: 1fr; }
      .cardstyle .layout-container .left-layout .item-menu-box .menu-box-item {
        display: flex;
        flex-direction: row;
        padding: 15px;
        background-color: #48c5eb;
        border-radius: 5px;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer; }
        .cardstyle .layout-container .left-layout .item-menu-box .menu-box-item img {
          width: 20px;
          height: 20px;
          margin-right: 10px; }
        .cardstyle .layout-container .left-layout .item-menu-box .menu-box-item p {
          margin: 0px;
          color: #fff;
          font-size: 16px; }
  .cardstyle .layout-setting-container {
    display: flex;
    flex-direction: column; }
    .cardstyle .layout-setting-container .layout-setting-item {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 10px;
      padding: 20px 20px 10px;
      background-color: #f9f9f9;
      border-radius: 5px; }

.layout-setting-item .ant-row {
  margin-bottom: 2px !important; }

.color-item-wrapper {
  padding: 20px; }
  .color-item-wrapper .title {
    margin: 0px;
    padding: 5px;
    font-size: 18px; }
  .color-item-wrapper div {
    display: flex;
    border: 1px solid #F4F4F4;
    border-radius: 3px; }
    .color-item-wrapper div .color {
      width: 80px;
      height: 40px;
      border-radius: 0px;
      border: 0px;
      border-right: 1px solid #F4F4F4; }
    .color-item-wrapper div .code {
      margin: 0px;
      padding: 6px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px; }

.layout-wrapper {
  text-align: center;
  padding: 5px; }
  .layout-wrapper .ant-row {
    color: #000000; }
    .layout-wrapper .ant-row .ant-col {
      vertical-align: middle;
      line-height: 50px;
      background-color: #ccf4ff;
      border: 1px solid #ffffff; }

@media only screen and (max-width: 576px) {
  .cardstyle .progress-container {
    grid-template-columns: 1fr;
    grid-gap: 10px; }
  .cardstyle .setting-container {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start; }
  .cardstyle .layout-container {
    grid-template-columns: 1fr; }
  .cardstyle .layout-container .right-layout {
    grid-template-columns: 1fr; }
  .cardstyle .layout-setting-container .layout-setting-item {
    flex-direction: column; } }

@media only screen and (min-width: 576px) {
  .cardstyle .progress-container {
    grid-template-columns: 1fr;
    grid-gap: 10px; }
  .cardstyle .setting-container {
    flex-wrap: wrap; }
  .cardstyle .layout-container {
    grid-template-columns: 1fr; } }

@media only screen and (min-width: 768px) {
  .cardstyle .progress-container {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px; }
  .cardstyle .setting-container {
    flex-wrap: wrap; }
  .cardstyle .layout-container {
    grid-template-columns: 1fr; }
  .cardstyle .layout-setting-container .layout-setting-item {
    flex-direction: column; } }

@media only screen and (min-width: 1024px) {
  .cardstyle .layout-container {
    grid-template-columns: 300px 2fr; }
  .cardstyle .layout-setting-container .layout-setting-item {
    flex-direction: row; } }

.color-item-wrapper {
  padding: 20px; }
  .color-item-wrapper .title {
    margin: 0px;
    font-size: 18px;
    text-align: left; }
  .color-item-wrapper div {
    display: flex;
    border: 1px solid #dadada;
    border-radius: 5px; }
    .color-item-wrapper div .color {
      width: 80px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #dadada; }
    .color-item-wrapper div .code {
      margin: 0px;
      padding: 6px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #f9f9f9 !important;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card-theme {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  padding: 20px; }

.color-item-wrapper {
  padding: 20px; }
  .color-item-wrapper .title {
    margin: 0px;
    font-size: 18px;
    text-align: left; }
  .color-item-wrapper div {
    display: flex;
    border: 1px solid #dadada;
    border-radius: 5px; }
    .color-item-wrapper div .color {
      width: 80px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #dadada; }
    .color-item-wrapper div .code {
      margin: 0px;
      padding: 6px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #f9f9f9 !important;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card-theme {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  padding: 20px; }

.color-item-wrapper {
  padding: 20px; }
  .color-item-wrapper .title {
    margin: 0px;
    font-size: 18px;
    text-align: left; }
  .color-item-wrapper div {
    display: flex;
    border: 1px solid #dadada;
    border-radius: 5px; }
    .color-item-wrapper div .color {
      width: 80px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #dadada; }
    .color-item-wrapper div .code {
      margin: 0px;
      padding: 6px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #f9f9f9 !important;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card-theme {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  padding: 20px; }

.color-item-wrapper-contactus {
  padding: 0px; }
  .color-item-wrapper-contactus .title {
    margin: 0px;
    font-size: 18px;
    text-align: left; }
  .color-item-wrapper-contactus div {
    display: flex;
    border: 1px solid #dadada;
    border-radius: 5px; }
    .color-item-wrapper-contactus div .color {
      width: 40px;
      height: 32px;
      border-radius: 5px;
      border: 1px solid #dadada; }
    .color-item-wrapper-contactus div .code {
      margin: 0px;
      padding: 6px;
      margin-left: 0px;
      margin-right: auto;
      font-size: 18px; }

.add-button-contactus {
  width: 100%;
  background-color: #37ba00;
  color: #fff; }

.add-button-contactus:hover {
  color: #ffffff;
  border-color: #34a803; }

.color-item-wrapper {
  padding: 20px; }
  .color-item-wrapper .title {
    margin: 0px;
    font-size: 18px;
    text-align: left; }
  .color-item-wrapper div {
    display: flex;
    border: 1px solid #dadada;
    border-radius: 5px; }
    .color-item-wrapper div .color {
      width: 80px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #dadada; }
    .color-item-wrapper div .code {
      margin: 0px;
      padding: 6px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #f9f9f9 !important;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card-theme {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  padding: 20px; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.deliverymethod-page-wrapper .action-button-wrapper {
  padding: 10px 15px; }
  .deliverymethod-page-wrapper .action-button-wrapper .title-header {
    font-size: 20px;
    font-weight: bold;
    margin: 0px; }
  .deliverymethod-page-wrapper .action-button-wrapper .create-button {
    color: #ffffff;
    background-color: #37ba00; }
  .deliverymethod-page-wrapper .action-button-wrapper .create-button:hover {
    color: #ffffff;
    border-color: #34a803; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.banktransfer-container-wrapper {
  margin-bottom: 50px; }
  .banktransfer-container-wrapper .action-button-wrapper {
    padding: 10px 15px; }
    .banktransfer-container-wrapper .action-button-wrapper .title-header {
      font-size: 20px;
      font-weight: bold;
      margin: 0px; }
    .banktransfer-container-wrapper .action-button-wrapper .create-button {
      color: #ffffff;
      background-color: #37ba00; }
    .banktransfer-container-wrapper .action-button-wrapper .create-button:hover {
      color: #ffffff;
      border-color: #34a803; }

.customareacharts-component-wrapper {
  border-radius: 8px;
  border: 1px solid #efefef;
  width: 100%; }
  .customareacharts-component-wrapper .header-wrapper {
    padding: 10px; }
    .customareacharts-component-wrapper .header-wrapper .title {
      font-size: 16px;
      padding: 0px;
      font-weight: 700; }
    .customareacharts-component-wrapper .header-wrapper .value-wrapper {
      display: flex; }
      .customareacharts-component-wrapper .header-wrapper .value-wrapper .value {
        font-size: 30px;
        font-weight: bold;
        padding: 0px;
        padding-right: 10px;
        margin-bottom: -10px; }
      .customareacharts-component-wrapper .header-wrapper .value-wrapper .percentage-increase {
        padding-top: 18px;
        color: #51CF7A;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: -10px; }
      .customareacharts-component-wrapper .header-wrapper .value-wrapper .percentage-decrease {
        padding-top: 18px;
        color: #EB3C19;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: -10px; }
    .customareacharts-component-wrapper .header-wrapper .remark {
      color: #dfdad9; }
  .customareacharts-component-wrapper .graph-wrapper {
    background-color: #ffffff; }

.customareachartsextra-component-wrapper {
  border-radius: 8px;
  border: 1px solid #efefef;
  width: 100%; }
  .customareachartsextra-component-wrapper .header-wrapper {
    padding: 10px; }
    .customareachartsextra-component-wrapper .header-wrapper .title {
      font-size: 24px;
      padding: 0px; }
    .customareachartsextra-component-wrapper .header-wrapper .value-wrapper {
      display: flex; }
      .customareachartsextra-component-wrapper .header-wrapper .value-wrapper .value {
        font-size: 30px;
        font-weight: bold;
        padding: 0px;
        padding-right: 10px;
        margin-bottom: 10px; }
      .customareachartsextra-component-wrapper .header-wrapper .value-wrapper .percentage-increase {
        padding-top: 10px;
        color: #51CF7A;
        font-size: 18px; }
      .customareachartsextra-component-wrapper .header-wrapper .value-wrapper .percentage-decrease {
        padding-top: 10px;
        color: #EB3C19;
        font-size: 18px; }
    .customareachartsextra-component-wrapper .header-wrapper .remark {
      color: #dfdad9; }
  .customareachartsextra-component-wrapper .graph-wrapper {
    background-color: #ffffff; }
  .customareachartsextra-component-wrapper .datadetaillist-wrapper {
    padding: 20px; }
    .customareachartsextra-component-wrapper .datadetaillist-wrapper .item-datadetaillist-wrapper {
      display: flex; }
      .customareachartsextra-component-wrapper .datadetaillist-wrapper .item-datadetaillist-wrapper .name {
        width: 70%; }
      .customareachartsextra-component-wrapper .datadetaillist-wrapper .item-datadetaillist-wrapper .value-increase {
        width: 30%;
        color: #51CF7A; }
      .customareachartsextra-component-wrapper .datadetaillist-wrapper .item-datadetaillist-wrapper .value-decrease {
        width: 30%;
        color: #EB3C19; }

.header-component-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f2f2f2 !important; }

.header-component-wrapper .new-menu .ant-menu {
  background: transparent !important; }

.header-component-wrapper .new-menu p {
  margin-bottom: 0px;
  vertical-align: text-top !important;
  font-weight: 700; }

.ant-menu-horizontal {
  border-bottom: 0px solid #e8e8e8 !important; }

.header-right-item .header-right-item-username {
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  padding: 3px 15px;
  color: #8d97ad;
  text-align: left;
  vertical-align: top; }

.bm-overlay .bm-menu-wrap {
  top: 0px; }

.header-right-item .header-right-item-username p {
  margin-bottom: 0;
  font-weight: 700; }

.header-right-item {
  display: inline-block !important; }
  @media only screen and (max-width: 834px) {
    .header-right-item .header-right-item-avatar {
      display: none !important; }
    .header-right-item .header-right-item-username {
      display: none; } }

.header-right-item .header-right-item-notification {
  display: inline-block;
  font-size: 14px;
  color: #8d97ad;
  text-align: left;
  vertical-align: top;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer; }
  .header-right-item .header-right-item-notification .anticon {
    vertical-align: top;
    margin-top: 10px;
    color: #8d97ad; }

.header-right-item .header-right-item-avatar {
  display: inline-block;
  line-height: 46px;
  font-size: 18px;
  color: #8d97ad;
  vertical-align: bottom; }

.header-right-item .header-right-item-avatar .profile-avatar {
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: 50%; }

.header-right-item .header-right-item-logout {
  display: inline-block;
  line-height: 46px;
  font-size: 14px;
  color: #8d97ad;
  text-align: left;
  vertical-align: top; }
  .header-right-item .header-right-item-logout .anticon {
    vertical-align: top;
    margin-top: 10px;
    color: #8d97ad; }

.header-right {
  text-align: right;
  padding-right: 8px; }

.header-component-wrapper .ticket-chat-page-wrapper {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  height: 46px; }

.header-leftmenu-wrapper {
  display: none; }

.header-grid-space {
  display: none; }

@media screen and (max-width: 834px) {
  .header-leftmenu-wrapper {
    display: block !important; }
  .header-grid-space {
    display: block; }
  .header-grid-left .logo {
    text-align: center; }
  .header-grid-mid {
    display: none; }
  .ticket-chat-page-wrapper {
    grid-template-columns: 100px 1fr 100px; } }

#root
> div
> div.header-component-wrapper
> div.ticket-chat-page-wrapper
> div.header-grid-mid
> div
> ul
> li.ant-menu-item {
  border-radius: 0px !important; }

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 4px; }

.home-header-wrapper {
  display: none; }

.home-page-wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 300px 1fr; }
  .home-page-wrapper .left-colunm-wrapper {
    background-color: #ffffff;
    height: 100vh;
    overflow: overlay; }
  .home-page-wrapper .right-colunm-wrapper {
    background-color: #f7f7f7;
    padding: 10px;
    height: 100vh;
    overflow: overlay; }
    .home-page-wrapper .right-colunm-wrapper .container {
      background-color: #ffffff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      height: auto;
      min-height: 100%;
      border-radius: 8px;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
      .home-page-wrapper .right-colunm-wrapper .container .header {
        padding: 15px 20px;
        border-radius: 8px 8px 0px 0px;
        display: flex;
        border-bottom: 1px solid #e8e8e8;
        flex-direction: row;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 70px;
        align-items: center;
        z-index: 3;
        background-color: #fff; }
        @media only screen and (max-width: 834px) {
          .home-page-wrapper .right-colunm-wrapper .container .header {
            left: 20px;
            margin-top: 10px !important;
            top: 0px !important;
            position: relative !important;
            width: calc(100% - 40px);
            height: 50px; } }
        .home-page-wrapper .right-colunm-wrapper .container .header .title {
          margin-bottom: 0px;
          font-size: 24px;
          color: #000;
          font-weight: 500; }
        .home-page-wrapper .right-colunm-wrapper .container .header .filter .ant-select-selection {
          border-radius: 5px !important;
          border: 1px solid #f0f0f0 !important;
          background: #fdfdfd;
          font-size: 14px;
          font-weight: 500; }

.burger-menu {
  display: none; }

.customareacharts-component-wrapper {
  margin-bottom: 10px; }

.dashboard-manager-wrapper {
  padding: 0px 10px 10px 10px; }
  .dashboard-manager-wrapper .title {
    font-size: 20px;
    text-align: left;
    margin: 0;
    padding: 15px 10px; }
  .dashboard-manager-wrapper .article-list-wrapper {
    display: flex; }
    .dashboard-manager-wrapper .article-list-wrapper .ant-card-meta-description {
      height: 130px !important; }
    .dashboard-manager-wrapper .article-list-wrapper .ant-card-bordered {
      border-radius: 5px; }
    .dashboard-manager-wrapper .article-list-wrapper .ant-card-cover img {
      border-radius: 5px;
      width: 100%; }
    .dashboard-manager-wrapper .article-list-wrapper .article-item-detail-wrapper {
      height: 100%; }
      .dashboard-manager-wrapper .article-list-wrapper .article-item-detail-wrapper .title {
        font-size: 16px;
        padding-left: 10px;
        margin: 0;
        padding-top: 20px; }
      .dashboard-manager-wrapper .article-list-wrapper .article-item-detail-wrapper .description {
        font-size: 14px; }
    .dashboard-manager-wrapper .article-list-wrapper .ant-card-body {
      padding: 10px !important; }
  .dashboard-manager-wrapper .card-container {
    display: flex;
    flex-direction: row; }
    .dashboard-manager-wrapper .card-container .left-img {
      width: 50%; }
      .dashboard-manager-wrapper .card-container .left-img img {
        width: 100%; }
    .dashboard-manager-wrapper .card-container .right-text {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: auto;
      padding: 10px; }
      .dashboard-manager-wrapper .card-container .right-text .text-header {
        height: 40%; }
      .dashboard-manager-wrapper .card-container .right-text .text-description {
        height: 60%; }

.dashboard-administrator-wrapper {
  height: 100%; }
  .dashboard-administrator-wrapper .title {
    font-size: 16px;
    margin: 0px;
    padding: 15px 20px;
    text-align: left; }
  .dashboard-administrator-wrapper .circle-chart {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding: 0px 20px 15px 20px; }
  .dashboard-administrator-wrapper .section2-dashboard {
    width: 100%;
    overflow: overlay; }
    .dashboard-administrator-wrapper .section2-dashboard div > div > div > div > div > div > div > table > thead > tr > th > span {
      height: 35px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
      font-weight: 500; }
    .dashboard-administrator-wrapper .section2-dashboard .ticket-chat-table-wrapper {
      overflow: overlay;
      width: 100%;
      min-width: 1000px; }

@media only screen and (max-width: 1024px) {
  .dashboard-administrator-wrapper .circle-chart {
    grid-template-columns: repeat(2, 1fr); } }

@media only screen and (max-width: 834px) {
  .dashboard-administrator-wrapper {
    margin-top: 70px;
    z-index: 1; }
  .dashboard-manager-wrapper {
    margin-top: 0px;
    z-index: 1; }
  .home-page-wrapper {
    grid-template-columns: 1fr; }
  .dashboard-administrator-wrapper .circle-chart {
    grid-template-columns: 1fr 1fr; }
  .burger-menu {
    display: block;
    position: absolute;
    height: 70px; }
  .home-page-wrapper .right-colunm-wrapper .container .header .title {
    margin-left: 50px; } }

@media only screen and (max-width: 414px) {
  .dashboard-administrator-wrapper .circle-chart {
    grid-template-columns: 1fr; }
  .home-page-wrapper .right-colunm-wrapper .container .header .title {
    margin-left: 50px; } }

@media only screen and (min-width: 1024px) {
  .home-page-wrapper .right-colunm-wrapper .container .header {
    position: relative; } }

@media screen and (max-width: 992px) {
  .ticket-chat-page-wrapper {
    grid-template-columns: 1fr; }
  .home-header-wrapper {
    display: block; }
  .right-colunm-wrapper .container .header {
    top: 100px !important; } }

.language-card .ant-select {
  display: block; }


.ant-input {
  font-size: 14px !important; }

.ant-table-thead {
  background: #eaeaea !important; }

.ant-table-thead .ant-input {
  border: 0px !important;
  border-radius: 4px !important; }

.ant-table-thead > tr > th {
  border-bottom: 0px !important;
  background: transparent !important;
  padding: 0px !important;
  text-align: center !important; }

.ant-form-item-label {
  line-height: 32px !important; }

.content-style {
  width: 100%;
  height: 100%; }

.ant-table-thead > tr > th .ant-table-header-column {
  width: 100%;
  padding: 10px 3px 0px 3px; }

.ant-table-thead > tr > th:first-child .ant-table-header-column {
  padding-left: 12px; }

.ant-table-thead > tr > th:last-child .ant-table-header-column {
  padding-right: 12px; }

.ant-select-sm .ant-select-selection--single {
  height: 28px !important; }

.ant-table-column-title > div > div > span {
  height: 28px !important; }

.ant-table-column-title > div > div > span > div > input {
  height: 28px !important; }

.ant-table-tbody tr {
  border-radius: 6px !important; }

.ant-table-tbody tr > td {
  border: 0px !important; }

.ant-table-tbody tr:nth-child(odd) {
  background-color: #ffffff !important; }

.ant-table-tbody tr:nth-child(even) {
  background-color: #f3f3f3 !important; }

.ant-table-tbody td:not(.original-style-input-table) {
  padding: 0px;
  height: 35px !important; }

.ant-table-tbody td:not(.original-style-input-table) div {
  font-size: 12px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  font-weight: 500; }

.ant-calendar-picker {
  width: 100% !important; }

.clickable-item {
  cursor: pointer; }
  .clickable-item:hover {
    background-color: #e0e0e0; }
  .clickable-item .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 0px !important; }
  .clickable-item .ant-table-thead .ant-input {
    border-top: 0.5px solid #ccc !important;
    border-bottom: 1px solid #ccc !important;
    border-right: 0px solid #ccc !important;
    border-left: 0.5px solid #ccc !important;
    border-radius: 0px !important; }
  .clickable-item .ant-table-thead > tr > th {
    border-bottom: 0.5px solid #ccc !important; }
  .clickable-item .ant-select-selection {
    border-radius: 0px !important; }
  .clickable-item .content-style {
    width: 100%;
    height: 100%; }
  .clickable-item .ant-table-thead > tr > th .ant-table-header-column {
    width: 100%; }
  .clickable-item .ant-select-sm .ant-select-selection--single {
    height: 40px !important; }
  .clickable-item .ant-table-column-title > div > div > span > div > input {
    height: 40px; }
  .clickable-item .clickable-item {
    cursor: pointer; }
    .clickable-item .clickable-item:hover {
      background-color: #e0e0e0; }
  .clickable-item .flex-1 {
    flex: 1 1; }
    @media screen and (max-width: 800px) {
      .clickable-item .flex-1 {
        flex-basis: auto; } }
  .clickable-item .flex-space-between {
    justify-content: space-between; }
  .clickable-item .filter-input {
    height: 25px;
    width: 100%; }
  .clickable-item .filter-input:focus {
    outline: none; }
  .clickable-item .ant-table-thead .icon {
    height: 31.8px !important; }
  .clickable-item .ant-input:focus {
    outline: none !important; }
  .clickable-item .ant-calendar-picker {
    width: 100% !important; }
  .clickable-item .ant-calendar-picker-icon {
    margin-right: 5px; }
  .clickable-item .ant-input-number {
    width: 100% !important; }
  .clickable-item .ant-select-selection--single:focus {
    outline: none !important; }
  .clickable-item .ant-calendar-picker-input {
    background-color: #fafafa !important;
    border: 0px solid #d9d9d9 !important;
    border-top-width: 0px; }
  .clickable-item .ant-btn-group {
    position: relative !important;
    width: 100% !important; }
  .clickable-item .ant-btn-group:hover .ant-dropdown-trigger {
    display: inline-block !important; }
  .clickable-item .ant-btn-group, .clickable-item .ant-btn-default {
    border: none !important;
    padding: 0px !important;
    font-weight: 600 !important;
    font-size: 14px !important; }
  .clickable-item .ant-dropdown-trigger {
    display: none !important;
    position: absolute !important;
    right: 0 !important; }
  .clickable-item .ant-dropdown-menu-submenu-vertical .ant-dropdown-menu-submenu {
    padding: 0px !important; }
  .clickable-item .ant-menu-item, .clickable-item .ant-menu-submenu {
    line-height: 30px !important;
    height: 30px !important;
    padding: 0px 20px !important;
    width: 170px !important; }
  .clickable-item .ant-menu-item-group .ant-menu-item-group-title {
    padding: 0px !important; }
  .clickable-item .ant-menu-root {
    box-shadow: 1px 18px 40px -18px rgba(0, 0, 0, 0.75) !important; }
  .clickable-item .ant-menu-submenu-vertical .ant-menu-submenu-title {
    padding: 0px !important;
    margin: 0px !important;
    line-height: 30px !important; }
  .clickable-item .ant-menu-vertical .ant-menu-item:after {
    content: inherit !important; }
  .clickable-item .ant-btn-default {
    background: transparent !important; }
  .clickable-item table.scroll {
    border-collapse: collapse; }
    .clickable-item table.scroll thead {
      padding: 0px; }
      .clickable-item table.scroll thead td {
        background: #f2f2f2;
        border: 1px #ccc solid;
        overflow: hidden; }
        .clickable-item table.scroll thead td input, .clickable-item table.scroll thead td .ant-select {
          border: 0px !important; }
    .clickable-item table.scroll tbody {
      overflow-y: scroll;
      max-height: 200px; }
      .clickable-item table.scroll tbody tr {
        border: 1px #ccc solid; }
  .clickable-item .table-wrapper {
    position: relative;
    height: 55vh;
    overflow: scroll; }
  .clickable-item .table-content-wrapper {
    position: relative;
    height: 20vh;
    overflow: scroll; }
  .clickable-item .ant-table {
    font-size: 12px !important; }
  .clickable-item .ant-table-thead > tr > th {
    padding: 0px !important; }
    .clickable-item .ant-table-thead > tr > th .anticon-filter {
      margin-left: 0 !important;
      font-size: 15px; }
  .clickable-item .ant-dropdown-trigger {
    display: block !important;
    position: relative !important;
    color: #000; }
  .clickable-item .ant-table-tbody tr:nth-child(odd) {
    background-color: #f3f3f3; }
  .clickable-item .ant-table-tbody tr:nth-child(even) {
    background-color: #ffffff; }
  .clickable-item .ant-table-tbody td:not(.original-style-input-table) {
    position: relative;
    height: 40px;
    padding: 5px 8px !important;
    font-weight: 500; }
    .clickable-item .ant-table-tbody td:not(.original-style-input-table) div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      line-height: 35px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 14px; }
  .clickable-item .original-style-input-table {
    padding: 2px !important; }
  .clickable-item .subcontent-table .ant-table-tbody tr td:last-of-type {
    background: none !important;
    border-bottom: 1px solid #e9e9e9; }
  .clickable-item .subcontent-table .ant-table-tbody tr:hover > td {
    background: #ecf6fd !important; }
  .clickable-item th {
    white-space: nowrap;
    overflow: hidden; }
  .clickable-item .column-title {
    padding: 10px 8px;
    position: relative;
    font-size: 15px; }
    .clickable-item .column-title .column-icon {
      display: none;
      cursor: pointer;
      position: absolute;
      right: -1px;
      top: -1px;
      bottom: 0px; }
    .clickable-item .column-title .icon {
      color: #EFEFF7;
      width: 20px;
      padding: 10px 7px;
      font-size: 8px;
      background: #85B3E4; }

.original-style-input-table {
  padding: 2px !important; }

.subcontent-table .ant-table-tbody tr td:last-of-type {
  background: none !important;
  border-bottom: 1px solid #e9e9e9; }

.subcontent-table .ant-table-tbody tr .active {
  display: block !important; }

.subcontent-table .ant-table-tbody tr .column-title:hover .column-icon {
  display: block; }

th {
  white-space: nowrap;
  overflow: hidden; }

.column-title {
  padding: 6px 0px;
  text-align: left;
  position: relative;
  font-size: 12px; }
  .column-title .column-icon {
    display: none;
    cursor: pointer;
    position: absolute;
    right: -1px;
    top: -1px;
    bottom: 0px; }
  .column-title .icon {
    color: #EFEFF7;
    width: 20px;
    padding: 10px 7px;
    font-size: 8px;
    background: #85B3E4; }
  .column-title .ant-pagination {
    margin: 16px !important;
    margin-top: 8px !important; }
    .column-title .ant-pagination li {
      line-height: 30px !important;
      vertical-align: middle; }
    .column-title .ant-pagination.pagination {
      position: absolute;
      right: 0;
      bottom: 0px; }
  .column-title .ant-table-header {
    background: transparent;
    background-color: transparent;
    margin-bottom: -6px !important;
    min-width: 0px !important; }
    .column-title .ant-table-header thead > tr {
      height: 0 !important; }
  .column-title .font-normal {
    font-style: normal !important;
    vertical-align: top;
    display: inline-block;
    max-width: 55%; }
  .column-title .ant-table-placeholder {
    height: calc(30vh - 90px); }

.ant-table table {
  border-radius: 6px !important; }

.ant-table-header {
  background: none;
  background-color: transparent !important;
  margin-bottom: 0px !important;
  min-width: 0px !important;
  overflow: hidden !important;
  border-radius: 6px !important;
  padding: 0px;
  padding-right: 4px; }
  .ant-table-header thead > tr {
    height: 0 !important; }
  .ant-table-header .shelf-life-header {
    padding: 25px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #9c9c9c;
    border-top: 1px solid #9c9c9c; }
    .ant-table-header .shelf-life-header .shelf-life-container {
      display: flex;
      align-items: center;
      margin-left: 20px; }
      .ant-table-header .shelf-life-header .shelf-life-container .Select {
        margin: 0 10px; }
  .ant-table-header .select-item {
    background: #d2d2d2; }
  .ant-table-header .last .ant-menu-submenu-vertical .ant-menu {
    left: -100% !important; }

.cssload-piano {
  margin: auto;
  margin-top: 150px;
  width: 39px;
  height: 10px;
  font-size: 10px; }

.cssload-piano > div {
  height: 100%;
  width: 100%;
  display: block;
  margin-bottom: 0.6em;
  animation: stretchdelay 1.38s infinite ease-in-out;
  -o-animation: stretchdelay 1.38s infinite ease-in-out;
  -ms-animation: stretchdelay 1.38s infinite ease-in-out;
  -webkit-animation: stretchdelay 1.38s infinite ease-in-out;
  -moz-animation: stretchdelay 1.38s infinite ease-in-out; }

.cssload-piano .cssload-rect2 {
  animation-delay: -1.15s;
  -o-animation-delay: -1.15s;
  -ms-animation-delay: -1.15s;
  -webkit-animation-delay: -1.15s;
  -moz-animation-delay: -1.15s; }

.cssload-piano .cssload-rect3 {
  animation-delay: -0.92s;
  -o-animation-delay: -0.92s;
  -ms-animation-delay: -0.92s;
  -webkit-animation-delay: -0.92s;
  -moz-animation-delay: -0.92s; }

@keyframes stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    background-color: #108fe9;
    box-shadow: 0 0 0 rgba(10, 10, 10, 0.1); }
  20% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    background-color: #00b192;
    box-shadow: 0 5px 6px rgba(10, 10, 10, 0.4); } }

@-webkit-keyframes stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleX(0.8);
    background-color: #108fe9;
    box-shadow: 0 0 0 rgba(10, 10, 10, 0.1); }
  20% {
    -webkit-transform: scaleX(1);
    background-color: #00b192;
    box-shadow: 0 5px 6px rgba(10, 10, 10, 0.4); } }

@media screen and (max-width: 1024px) {
  .main-table-small2 .ant-table-body > table {
    font-size: 12px !important; }
  .main-table-small2 .ant-table-header > table {
    font-size: 12px !important;
    font-weight: bold !important; } }

.eor-filter-date div > div {
  margin: 0px !important; }

.table-width-forresponsive {
  height: 70vh; }

.table-ticket_chat > div > div > div > div > div > div.ant-table-body > table > tbody > tr > td:nth-child(6) > div {
  margin-top: 1px;
  height: 33px;
  width: 95%;
  line-height: 33px;
  display: inline-block !important; }

.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 6px !important; }

.table-layout .ticket-chat-menu {
  margin-bottom: 0px !important; }

.sales-order-table-btn {
  display: flex !important;
  align-items: center;
  width: 100%;
  cursor: pointer; }
  .sales-order-table-btn span {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

.paymentorder-sub-page-wrapper .action-button-wrapper {
  padding: 10px 15px; }
  .paymentorder-sub-page-wrapper .action-button-wrapper .title-header {
    font-size: 20px;
    font-weight: bold;
    margin: 0px; }
  .paymentorder-sub-page-wrapper .action-button-wrapper .create-button {
    color: #ffffff;
    background-color: #37ba00; }
  .paymentorder-sub-page-wrapper .action-button-wrapper .create-button:hover {
    color: #ffffff;
    border-color: #34a803; }
  .paymentorder-sub-page-wrapper .action-button-wrapper .create-button:disabled {
    color: #00000040;
    background-color: #f5f5f5;
    border-color: #f5f5f5; }

.title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px; }

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #ffffff;
  height: calc(95% - 10vh);
  margin: 0; }

.header-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px; }

.table-main {
  display: grid;
  grid-template-columns: 2fr 1fr; }

.table-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px 0; }
  .table-layout .ticket-chat-menu {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center; }
  .table-layout .left-menu {
    text-align: left; }
  .table-layout .right-menu {
    text-align: right; }

.first-class {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 10px;
  height: calc(100vh - 60px);
  overflow: overlay;
  padding: 20px; }

.second-class {
  display: grid;
  grid-template-columns: 100%;
  height: calc(100vh - 60px);
  overflow: overlay;
  padding: 20px; }
  .second-class .second-section {
    display: none; }

.ant-modal-body > div > div > div > div > div > div > table > thead {
  height: 40px; }

.ant-table-column-title > div > div > span {
  height: 40px; }

.ant-select-sm .ant-select-selection--single {
  height: 40px; }

body > div > div > div.ant-modal-wrap > div > div.ant-modal-content > div.ant-modal-body > div > div > div > div > div > div > table > thead > tr {
  height: 40px; }

body > div > div > div.ant-modal-wrap > div > div.ant-modal-content > div.ant-modal-body > div > div > div > div > div > div > table > thead > tr > th > span {
  text-align: center;
  font-size: 16px; }

body > div > div > div.ant-modal-wrap > div > div.ant-modal-content > div.ant-modal-body > div > div > div > div > div > div > table > tbody > tr {
  text-align: center; }

.ant-table-column-title .ant-select-selection {
  border: 0px !important; }

.ant-table-column-title .ant-select-sm .ant-select-selection__rendered {
  line-height: 27px; }

.filter .ant-select-selection {
  border-radius: 5px !important; }

@media only screen and (max-width: 834px) {
  .first-class {
    grid-template-columns: 100%; }
    .first-class .first-section {
      order: 2; }
  .table-layout {
    flex-direction: column; } }

@media only screen and (max-width: 414px) {
  .table-layout .left-menu {
    text-align: center; }
  .table-layout .right-menu {
    text-align: center; } }

.table-layout p {
  margin-bottom: 0px !important; }

.first-section td.ant-table-row-cell-break-word .ant-checkbox-wrapper {
  position: absolute;
  margin-top: -3px;
  left: 12px; }

