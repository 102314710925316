
.title{
    margin: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
}

.cardstyle {
    display: flex;
    flex-direction: column;
    padding: 20px !important;
    overflow: overlay;
    width: 100%;
    background-color: #ffffff;
    height: calc(95% - 10vh);
    margin: 0;
    .ant-card-body{
        .ant-form{
            
        max-width: 850px;
        margin: 0 auto;
        .ant-table-tbody td:not(.original-style-input-table){
            padding-left:16px;
        }
        }
    }
}
.cardstyle.mw-l {
    .ant-card-body{
        .ant-form{
        max-width: 1200px;
        }
    }
}

.header-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
}
