.login-page-wrapper {
  display: flex;
  flex-direction: row;
  .left-colunm-wrapper-login {
    background-color: #65D2F3;
    background-image: url('../../../resource/assets/loginleftbg.png');
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 100px 0 50px 5%;
    @media only screen and (max-width: 834px) {
      display: none;
    }
    .logo{
      position: absolute;
      top:8%;
    }
    .title {
      margin: 10px 0;
      font-size: 70px;
      font-weight: 400;
    }
    .border-title {
      border-bottom: 12px solid #48c5eb;

      margin-left: 12px;
      width: 100px;
      margin-bottom: 30 px;
    }
    .login-text{
    }
    .sub-title {
      font-size: 32px;
      line-height: 50px;
      font-weight: 400;
      margin: 0;
      color: #fff;
    }
    .sub-title-2{
      font-size: 42px;
      line-height: 50px;
      font-weight: 700;
      margin: 0;
      color: #fff;
      margin-bottom: 100px;
    }
    .sub-title-3{

      font-size: 18px;
      line-height: 2.5rem;
      font-weight: 400;
      margin: 0;
      margin-top: 70px;
      color: #fff;
    }
    .signin-button {
      background-color: #ffffff;
      border-radius: 20px;
      padding: 10px 20px;
      p {
        margin: 0;
      }
    }
    .login-footer {
      position: absolute;
      bottom: 8%;
      text-align: left;
      color: #fff;
    }
  }
  .right-colunm-wrapper {
    background-color: #fcfcfc;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .header-wrapper {
      background-color: #ffffff;
      position: absolute;
      top: 0px;
      width: 100%;
      display: flex;
      height: 50px;
      border-bottom: 1px solid #ebebeb;
      justify-content: flex-end;
      align-items: center;
      .ant-row{
        width: 100%;
      }
      .header-wrapper-left{
        display: inline-block;
        .logo{
          display: none;
          @media only screen and (max-width: 834px) {
            display: block;
            padding-top: 9px;
            padding-left: 10px;
          }
        }
      }
      .header-wrapper-right{
        text-align: right;
        display: inline-block;
        @media only screen and (max-width: 834px) {
          float: right;
        }
       
      }
      .name {
        color: #626262;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 7px;
        display: inline-block;
        @media only screen and (max-width: 834px) {
          display: none;
        }
      }
      .popover{
        margin:10px 30px;
        display: inline-block;
      }
      .logout{
        margin-right:30px;
        display: inline-block;
      }
      .ant-badge{
        font-size: 22px;
        cursor: pointer;
      }
      a{
        color: #626262;
      }
      .anticon{
        font-size: 22px;
      }
    }
    .email-confirmed-wrapper {
      position: absolute;
      top: 60px;
      width: 100%;
      display: flex;
      padding: 10px 20px;
      .ant-alert-warning {
        background-color: #fffbe6;
        border: 1px solid #ffe58f;
        width: 100%;
    }
      .email-confirmed {
        color: #ffffff;
      }
    }
    .container-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .company-name-list-wrapper {
        .item-company-name-wrapper {
          display: flex;
          border: 1px solid #d9d9d9;
          padding: 20px;
          margin: 5px;
          font-size: 20px;
          color: #000000;
          cursor: pointer;
          height: 65px;
          width: 400px;
            .anticon{
              position: absolute;
              right: 20px;
              font-size: 18px;
              margin-top: 3px;
            }
            span{
              font-size: 18px;
            }
          
        }
        .item-company-name-wrapper:hover {
          border: 1px solid #a4e4f7;
          background-color: #edf8fb;
          color: #48c5eb;
        }
      }
      .container-empty-wrapper {
        // .image-wrapper{

        // }
        .description-wrapper {
          text-align: center;
          color: #e3e3e3;
          opacity: 0.5;
          .title {
            font-size: 18px;
          }
          .detail {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.ant-popover-inner-content{
cursor: default;
}
.notification-wrapper {
  width: 400px;
  
  .item-notification-wrapper {
    .button-wrapper {
      display: flex;
      button{
        margin-top:10px;
        margin-right: 10px;
      }
    }
    .title {
      font-size: 14px;
      margin: 5px;
    }
    p {
      font-size: 12px;
      margin: 0px;
      text-align: left;
    }
  }

}
.notification-empty-wrapper {
  width: 400px;
}
@media only screen and (min-width: 1440px) {
  .login-page-wrapper .left-colunm-wrapper {
    padding: 150px 0 50px 100px;
  }
  .input-group {
    width: 60%;
  }
}
@media only screen and (max-width: 1280px) {
}
@media only screen and (max-width: 1024px) {
  .login-page-wrapper .left-colunm-wrapper {
    justify-content: center;
    padding: 50px;
  }
  .login-page-wrapper .left-colunm-wrapper .login-footer {
    margin-right: 50px;
  }
}
@media only screen and (max-width: 834px) {
  .left-colunm-wrapper {
    display: none !important;
  }
  .right-colunm-wrapper {
    width: 100% !important;
  }
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 414px) {
  .login-page-wrapper .right-colunm-wrapper .container .input-group {
    width: 100%;
  }
}
@media only screen and (max-width: 375px) {
}
