.customareachartsextra-component-wrapper {
    border-radius: 8px;
    border: 1px solid #efefef;
    width: 100%;
    .header-wrapper {
        padding: 10px;
        .title {
            font-size: 24px;
            padding: 0px;
        }
        .value-wrapper {
            display: flex;
            .value {
                font-size: 30px;
                font-weight: bold;
                padding: 0px;
                padding-right: 10px;
                margin-bottom: 10px;;
            }
            .percentage-increase {
                padding-top: 10px;
                color: #51CF7A;
                font-size: 18px;
            }
            .percentage-decrease {
                padding-top: 10px;
                color: #EB3C19;
                font-size: 18px;
            }
        }
        .remark{
            color: #dfdad9;
        }
    }
    .graph-wrapper {
        background-color: #ffffff;
    }
    .datadetaillist-wrapper{
        padding: 20px;
        .item-datadetaillist-wrapper{ 
            display: flex; 
            .name{
                width: 70%;
            }
            .value-increase{
                width: 30%;
                color: #51CF7A; 
            }
            .value-decrease {
                width: 30%;
                color: #EB3C19;
            }
        }
    }
}
