// .ticket-chat-page-wrapper {
//   .detail {
//     padding: 10px;
//     .ant-form-item {
//       margin-bottom: 0px;
//     }
//   }

//   .tabpane-customer-wrapper,
//   .tabpane-file-wrapper {
//     text-align: start;
//     padding: 10px;
//     .image-wrapper {
//       width: 100%;
//       text-align: center;
//       img {
//         background-color: #f2f2f2;
//         border-radius: 50%;
//       }
//     }
//     .edit-button {
//       color: #ffffff;
//       background-color: #1fd963;
//       border-radius: 3px;
//       border: none;
//       padding: 5px;
//       cursor: pointer;
//     }
//     .title {
//       width: 100%;
//       margin-bottom: 5px;
//     }
//   }
//   .divider {
//     width: 100%;
//     border-bottom: 1px solid #f2f2f2 !important;
//     margin: 5px;
//   }

//   .chatlist-content-wrapper {
//     width: 100%;
//     .item-wrapper {
//       display: flex;
//       padding: 2px;
//       cursor: pointer;
//       .image-wrapper {
//         padding: 2px;
//         padding: 10px 0px;
//         img {
//           border-radius: 50%;
//           background-color: #f2f2f2;
//         }
//       }
//       .detail-wrapper {
//         padding: 2px;
//         width: 100%;

//         p {
//           margin: 0px;
//         }
//         .name {
//           font-size: 16px;
//         }
//         .message {
//         }
//         .datetime {
//         }
//         .status {
//         }
//       }
//     }
//   }
// }

.modal-ticket {
  width: 1000px;
}
.ant-modal-content {
  border-radius: 10px !important;
  .ant-modal-header {
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    background: #f3f3f3;
  }
  .ant-modal-footer {
    border-radius: 10px;
  }
  .ant-modal-body > div > div > label {

    font-size: 16px;
    font-weight: 500;
  }
}


// chat-room

.chatroom-ticket {
  position: absolute;
  left: 0;
  background-color: #fff;
  z-index: 3;
  height: calc(100vh - 46px);
    top: 46px !important;
  width: 100%;
}

.ticket-chat-page-wrapper {
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  position: relative;
  .left-section-chat {
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ececec;
    .left-section-chat-top-box{
      border-bottom: 1px solid #ececec;
    }

    .left-sec-chat-header {
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .left-sec-chat-list {
      overflow: overlay;
      height: calc(100vh - 99px);
    }
    .ant-list-split .ant-list-item {
      border: none;
    }
    
  }
  .center-section-chat {
    height: 100vh;
    .center-sec-chat-header {
      display: none;
    }
  }
  .right-section-chat {
    height: 100vh;
    overflow: overlay;
    border-left: 1px solid #ececec;
    
    .ant-select-selection{
      background: #f7f7f7;
    }
    .ant-input{
      border: 1px solid #f5f5f5;
    }
    .detail {
      display: flex;
      flex-direction: column;
      padding: 10px;
      // border: 1px solid #9e9e9e52;
      overflow: overlay;

      .image-wrapper {
        display: flex;
        justify-content: center;
        img {
          border-radius: 50px;
        }
      }
      p {
        text-align: center;
        margin: 20px;
        font-weight: 500;
      }
      .divider {
        border-top: 1px solid #ececec;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
      }
      .edit-button {
        background-color: #1fd963;
        border: none;
        padding: 10px;
        color: #fff;
        font-weight: 500;
        border-radius: 7px;
        width: 200px;
        cursor: pointer;
      }
    }
    .btn-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: absolute;
      // bottom: 10px;
      margin-bottom: 10px;
      right: 65px;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      color: #48c5eb;
    }
    .ant-tabs-bar{
      margin-bottom: 0px;
    }
    .ant-tabs-ink-bar {
      background-color: #48c5eb;
      height: 3px;
    }
    .tag-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .tag-section {
      text-align: center;
    }
    .tag-btn {
      border: 1px solid #48c5eb;
      color: #48c5eb;
      cursor: pointer;
      padding: 3px 15px;
      margin: 3px;
      height: 100%;
    }
    .tag-list {
      padding: 3px 15px;
      margin: 3px;
    }
    .right-tab-css {
      height: calc(100vh - 47px);
      overflow: overlay;

      .ant-form-item-label > label {
        color:#8c8c8c !important;
      }
      .note-container {
        background-color: #48c5eb14;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;

        .note-time {
          font-size: 12px;
        }
        .note-text1 {
          font-size: 12px;
        }
        .note-text2 {
          font-size: 16px;
          color: #00abdf;
        }
      }
    }
  }
}


.chatlist-content-wrapper {
  display: flex;
  flex-direction: column;
  .item-wrapper {
    display: grid;
    grid-template-columns: 50px 1fr 90px;
    .image-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: start;
    }
    .text-wrapper {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      .name {
        margin: 0;
        padding-bottom: 2;
        font-weight: 500;
        // color: #6c6c6c;
        color: #010101;
        font-size: 13px;
      }
      .message {
        margin: 0;
        font-weight: 300;
        // color: #adb5bd;
        color: #7a8187;
        font-size: 13px;
      }
      .message-read {
        margin: 0;
        font-weight: 700;
        color: #000;
        font-size: 13px;
      }
      
    }
    .time-wrapper {
      display: flex;
      flex-direction: column;
      
      .datetime {
        margin: 0;
        text-align: center;
        font-weight: 300;
        color: #adb5bd;
        font-size: 12px;
      }
    }
  }
}

.img-upp {
  width: 100%;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #48c5eb;
  font-weight: 500;
}
.ant-tabs-ink-bar {
  color: #48c5eb;
  height: 7px;
}
.ticket-product {
  display: flex;
  flex-direction: column;
  margin: 15px 0;

  img {
    display: flex;
    align-self: center;
  }
  p {
    font-size: 18px;
  }
  .list-box {
    display: flex;
    flex-direction: row;
  }
  .icon-list {
    height: 8px;
    width: 8px;
    background-color: #ff9900;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    margin-top: 7px;
  }
}

.ticket-attachment {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
}
.ticket-attachment-video {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
}
.attachment-title {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
  margin-left: 20px;
}
.ticket-attachment-file {
  display: grid;
  grid-template-columns: 1fr;
  padding: 10px;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
}



@media only screen and (max-width:576px) {
  .ticket-chat-page-wrapper .left-section-chat {
    display: none;
  }
  .ticket-chat-page-wrapper .right-section-chat {
    // display: none;
    position: absolute;
    right: 0;
    transition: 1s ease-in-out;
    width: 90%;
    z-index: 1;
  }
  .ticket-chat-page-wrapper {
    grid-template-columns: 100%;
  }
  .ticket-chat-page-wrapper .center-section-chat {
    height: calc(90vh - 90px);
  }
  .ticket-chat-page-wrapper .center-section-chat .center-sec-chat-header {
    display: block;
    padding: 10px;
  }
  .img-card-chat .first-sec {
    flex-direction: column;
  }
}
@media only screen and (min-width:576px) {
  .ticket-chat-page-wrapper .left-section-chat {
    display: none;
  }
  .ticket-chat-page-wrapper .right-section-chat {
    display: none;
  }
  .ticket-chat-page-wrapper {
    grid-template-columns: 100%;
  }
  .ticket-chat-page-wrapper .center-section-chat {
    height: calc(90vh - 90px);
  }
  .ticket-chat-page-wrapper .center-section-chat .center-sec-chat-header {
    display: block;
    padding: 10px;
  }
}
@media only screen and (min-width:768px) {
  .ticket-chat-page-wrapper .left-section-chat {
    display: none;
  }
  .ticket-chat-page-wrapper .right-section-chat {
    display: none;
  }
  .ticket-chat-page-wrapper {
    grid-template-columns: 100%;
  }
  .ticket-chat-page-wrapper .center-section-chat {
    height: calc(100vh - 47px);
    display: flex;
    flex-direction: column;
  }
  .ticket-chat-page-wrapper .center-section-chat .center-sec-chat-header {
    display: block;
    padding: 10px;
  }
  .img-card-chat .first-sec {
    flex-direction: row;
  }
  
}
@media only screen and (min-width:1024px) {
  .ticket-chat-page-wrapper {
    grid-template-columns: 300px 1fr;
  }
  .ticket-chat-page-wrapper .left-section-chat {
    display: flex;
    flex-direction: column;
  }
  .ticket-chat-page-wrapper .right-section-chat {
    display: none;
  }
  .ticket-chat-page-wrapper .center-section-chat .center-sec-chat-header {
    display: none;
    padding: 10px;
  }
  
}
@media only screen and (min-width:1200px) {
  .ticket-chat-page-wrapper {
    grid-template-columns: 300px 1fr 300px;
  }
  .ticket-chat-page-wrapper .right-section-chat {
    display: block;
  }
}
@media only screen and (min-width:1400px) {

}
@media only screen and (min-width:1600px) {

}

.left-section-chat .ant-btn span{
font-size: 14px;
}