.leftmenu-page-wrapper {
    min-height: 100vh;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: fit-content;
    overflow: hidden;

    .logo{
        text-align: center;
        margin-bottom: 10px;
    }

    .profile-wrapper {
        margin-bottom: 30px;
        .avatar {
            background-color: "#E6F7FF";
        }
        
    }
    .item-menu-wrapper {
        text-align: start;
        .icon-menu {
            margin-right: 10px;
        }
    }
    .new-menu{
        .left-menu-bottom{
            border-top: 1px solid #f0f0f0;
            margin-top: 15px;
            padding-top: 15px;
        }
    }
    
}
.hover-button:hover {
    color: #000;
    background-color: #47c5eb;
}
.btn-logout-section {
    margin-top: 20px;
    .btn-logout {
        color: #fff;
        background-color: #b9b9b9;
        border: none;
        padding: 5px 25px;
        border-radius: 5px;
        cursor: pointer;
    }
}
.broadcast-memu:hover{
    p{
        color: #1890ff !important;
    } 
}

.leftmenu-btn-hover:hover{
    color: #FFF !important;
    background-color: #47c5eb !important;
}


.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border-right: 0px !important;
}

.ant-menu-submenu-title, .ant-menu-item{
    text-align: left;
}
.ant-menu-item, .ant-menu-submenu-title{
    border-radius: 8px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: #fff !important;

}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a{
    color:rgba(0, 0, 0, 0.65);
}


.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
    display: none !important;
}



#root > div > div > div.left-colunm-wrapper > div > div.new-menu > ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline > li:nth-child(4) > div.ant-menu-submenu-title > i{
    display: none;
}