.title {
    margin: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
}

.cardstyle {
    display: flex;
    flex-direction: column;
    padding: 20px !important;
    overflow: overlay;
    width: 100%;
    background-color: #ffffff;
    height: calc(95% - 10vh);
    margin: 0;
}

.header-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
}

.package-container {
    text-align: right;
    .create-button {
        color: #ffffff;
        background-color: #37ba00;
    }
    .create-button:hover {
        color: #ffffff;
        border-color: #34a803;
    }
}

.review-container{
    .create-button {
        color: #ffffff;
        background-color: #37ba00;
    }
    .create-button:hover {
        color: #ffffff;
        border-color: #34a803;
    }
}


.upload-id-card,.upload-file-compant{
    color: #ddd;
    background: #fff;
    width: 400px;
    height: 33px;
    border-radius: 4px;
    border: 1px #ddd solid;
    overflow: hidden;
    margin-bottom: 10px;
    display: flex;
}

.title-table-comment{
    text-align: center;
}