.home-header-wrapper {
  display: none;
}
.home-page-wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 300px 1fr;
  .left-colunm-wrapper {
    background-color: #ffffff;
    height: 100vh;
    overflow: overlay;
  }
  .right-colunm-wrapper {
    background-color: #f7f7f7;
    padding: 10px;
    height: 100vh;
    overflow: overlay;

    .container {
      background-color: #ffffff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      height: auto;
      min-height: 100%;
      border-radius: 8px;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      .header {
        padding: 15px 20px;
        border-radius: 8px 8px 0px 0px;
        display: flex;
        border-bottom: 1px solid #e8e8e8;
        flex-direction: row;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 70px;
        align-items: center;
        z-index: 3;
        background-color: #fff;
        @media only screen and (max-width:834px) {
          left: 20px;
          margin-top: 10px !important;
          top: 0px !important;
          position: relative !important;
          width: calc(100% - 40px);
          height: 50px;
        }
        .title {
          margin-bottom: 0px;
          font-size: 24px;
          color: #000;
          font-weight: 500;
        }
        .filter {
          .ant-select-selection {
            border-radius: 5px !important;
            border: 1px solid #f0f0f0 !important;
            background: #fdfdfd;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.burger-menu {
  display: none;
}

.customareacharts-component-wrapper{
  margin-bottom: 10px;
}

.dashboard-manager-wrapper {
  padding: 0px 10px 10px 10px;
  .title {
    font-size: 20px;
    text-align: left;
    margin: 0;
    padding: 15px 10px;
  }

  .article-list-wrapper {
    display: flex;
    .ant-card-meta-description {
      height: 130px !important;
    }
    .ant-card-bordered {
      border-radius: 5px;
    }
    .ant-card-cover img {
      border-radius: 5px;
      width: 100%;
    }
    .article-item-detail-wrapper {
      height: 100%;

      .title {
        font-size: 16px;
        padding-left: 10px;
        margin: 0;
        padding-top: 20px;
      }
      .description {
        font-size: 14px;
      }
    }

    .ant-card-body {
      padding: 10px !important;
    }
  }
  .card-container {
    display: flex;
    flex-direction: row;
    .left-img {
      width: 50%;
      img {
        width: 100%;
      }
    }
    .right-text {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: auto;
      padding: 10px;
      .text-header {
        height: 40%;
      }
      .text-description {
        height: 60%;
      }
    }
  }
}

.dashboard-administrator-wrapper {
  height: 100%;
  .title {
    font-size: 16px;
    margin: 0px;
    padding: 15px 20px;
    text-align: left;
  }
  .circle-chart {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding: 0px 20px 15px 20px;
  }
  .section2-dashboard {
    width: 100%;
    overflow: overlay;

    div > div > div > div > div > div > div > table > thead > tr > th > span {
      height: 35px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
      font-weight: 500;
    }
    .ticket-chat-table-wrapper {
      overflow: overlay;
      width: 100%;
      min-width: 1000px;
    }
  }
}

@media only screen and (min-width: 1440px) {
}
@media only screen and (max-width: 1280px) {
}
@media only screen and (max-width: 1024px) {
  .dashboard-administrator-wrapper .circle-chart {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 834px) {
  .dashboard-administrator-wrapper {
    margin-top: 70px;
    z-index: 1;
  }
  .dashboard-manager-wrapper {
    margin-top: 0px;
    z-index: 1;
  }
  .home-page-wrapper {
    grid-template-columns: 1fr;
  }
  .dashboard-administrator-wrapper .circle-chart {
    grid-template-columns: 1fr 1fr;
  }
  .burger-menu {
    display: block;
    position: absolute;
    height: 70px;
  }
  .home-page-wrapper .right-colunm-wrapper .container .header .title {
    margin-left: 50px;
  }
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 414px) {
  .dashboard-administrator-wrapper .circle-chart {
    grid-template-columns: 1fr;
  }
  .home-page-wrapper .right-colunm-wrapper .container .header .title {
    margin-left: 50px;
  }
}
@media only screen and (min-width: 1024px) {
  .home-page-wrapper .right-colunm-wrapper .container .header {
    position: relative;
  }
}
@media screen and (max-width: 992px) {
  .ticket-chat-page-wrapper{
    grid-template-columns: 1fr;
  }
  .home-header-wrapper {
    display: block;
    
  }
  .right-colunm-wrapper {
    .container { 
      .header { 
        top: 100px !important; 
      }
    }
  }
}

.language-card .ant-select{
  display: block;
}