.cardstyle {
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: overlay;
    width: 100%;
    background-color: #ffffff;
    margin: 0;
}

.header-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
}

.image-upload-container .ant-row-flex{
    flex-flow: unset;
    
}

@media only screen and (max-width:576px) {
    .header-card {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}
@media only screen and (min-width:576px) {
    
}