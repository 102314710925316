.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div > div > textarea{margin:10px !important;}
.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div > div > dev > i{display: block;margin-bottom: 40px;}
// #root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div > div > div > div > div{display: block;margin-bottom: 40px;}
.ant-popover-inner-content{cursor: pointer !important;}


#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div:nth-child(1){padding-bottom: 120px !important;    height: 100% !important;}
#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div:nth-child(2){height: 120px !important;position: absolute !important;}
#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div > div > textarea{height: 100px !important;}
#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div{padding: 0px;}
#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div:nth-child(1) > div > div > div:nth-child(10) > div > div > div > span{font-size: 12px !important;}


.ant-form-item, .ant-select, .ant-select-dropdown, .ant-btn{font-size: 12px !important;}

.ticket-chat-page-wrapper{
    font-size: 12px !important;
    .ant-select-selection{
        border-radius:4px !important;
        border: 0px !important;
        background: #f5f5f5 !important;
      }
}

.ticket-chat-page-wrapper > div.ant-col-6 > div > div > div > div > div.ant-col-24 > div > p{
    margin-bottom: 0px !important;
    line-height: 2.5;
}

.ticket-chat-page-wrapper .ant-form-item-label > label {
    color:#000 !important;
    font-weight: bold;
}

.ticket-chat-page-wrapper .ant-form-item-label, .ant-form-item-control{
    line-height: 20px !important;
}
.ticket-chat-page-wrapper .ant-form-item-label{text-align: left !important}
.ticket-chat-page-wrapper .detail .ant-form-item{margin-bottom:10px !important}
.cticket-chat-page-wrapperhat .ant-select-selection{
    background-color: #fafafa;
    border: 0px solid #d9d9d9;
    border-top-width: 0px;
}


.ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li{
    border:0px;
    background-color: transparent;
    line-height: 25px;
}

::-webkit-scrollbar-track
{
	border-radius: 0px;
	background-color: transparent;
}

::-webkit-scrollbar
{
    width: 4px;
    height: 4px;
	background-color:transparent;
}

::-webkit-scrollbar-thumb
{
    border-radius: 4px !important;
	background-color: #ccc;
}
.notecss{
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 8px;
    border-radius: 8px;
    margin:10px 0px;
}
#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div:nth-child(1) > div > div > div > div > div > div > div > div > div > div > span{
    font-size: 12px !important;
}

#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div:nth-child(1) > div > div > div > div > div.rn-alignItems-1oszu61.rn-borderTopStyle-1efd50x.rn-borderRightStyle-14skgim.rn-borderBottomStyle-rull8r.rn-borderLeftStyle-mm0ijv.rn-borderTopWidth-13yce4e.rn-borderRightWidth-fnigne.rn-borderBottomWidth-ndvcnb.rn-borderLeftWidth-gxnn5r.rn-boxSizing-deolkf.rn-display-6koalj.rn-flexShrink-1qe8dj5.rn-flexBasis-1mlwlqe.rn-flexDirection-eqz5dr.rn-marginTop-1mnahxq.rn-marginBottom-p1pxzi.rn-marginLeft-11wrixw.rn-marginRight-1kb76zh.rn-minHeight-ifefl9.rn-minWidth-bcqeeo.rn-paddingTop-wk8lta.rn-paddingRight-9aemit.rn-paddingBottom-1mdbw0j.rn-paddingLeft-gy4na3.rn-position-bnwqim.rn-zIndex-1lgpqti > div{
    cursor: default !important;
}

#root > div > div.ant-row.ticket-chat-page-wrapper > div.ant-col-18 > div > div > div:nth-child(1) > div > div > div > div > div.rn-alignItems-1oszu61.rn-borderTopStyle-1efd50x.rn-borderRightStyle-14skgim.rn-borderBottomStyle-rull8r.rn-borderLeftStyle-mm0ijv.rn-borderTopWidth-13yce4e.rn-borderRightWidth-fnigne.rn-borderBottomWidth-ndvcnb.rn-borderLeftWidth-gxnn5r.rn-boxSizing-deolkf.rn-display-6koalj.rn-flexShrink-1qe8dj5.rn-flexBasis-1mlwlqe.rn-flexDirection-eqz5dr.rn-marginTop-1mnahxq.rn-marginRight-61z16t.rn-marginBottom-p1pxzi.rn-marginLeft-1jkjb.rn-minHeight-ifefl9.rn-minWidth-bcqeeo.rn-paddingTop-wk8lta.rn-paddingRight-9aemit.rn-paddingBottom-1mdbw0j.rn-paddingLeft-gy4na3.rn-position-bnwqim.rn-zIndex-1lgpqti > div{
    cursor: default !important;
}

.menu-bar-bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-top: 1px solid #e1e1e1;
    .ant-input{
        border-radius: 0px;
    }
    textarea.ant-input{
        min-height: 83px;
        resize: none;
    }
    .ant-input:focus {
        border: 0px !important;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0);
        box-shadow: 0 0 0 0px rgba(24, 144, 255, 0);
    }
}

.upload-file-btn {
    font-size: 35px;
    padding: 10px 25px;
    border: none;
    color: #47C5EB !important;
}
.upload-file-text {
    font-weight: 500;
}
.upload-file-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    
}
.chat-register-card {
    background-color: #fff;

    width: 250px;
    padding-bottom: 15px;
    border-radius: 13px 13px 0px 0px;
    margin:-9px -9px 0 -9px;
    img{
        border-radius: 13px 13px 0 0;
    }
}
.chat-regis-text {
    text-align: center;
    padding: 10px 0;
    p {
        margin: 0;
        font-size: 14px;
    }
}
.chat-regis-btn-container {
    text-align: center;
    .chat-regis-btn {
        background-color: rgb(72, 197, 235);
        color: rgb(255, 255, 255);
        border: 1px solid rgb(72, 197, 235);
        padding: 5px 20px;
        border-radius: 5px;
        width: 80%;
        font-size: 14px;
    }
}
@media only screen and (max-width:414px) {
    .upload-file-container {
        flex-direction: column;
    }
}

.img-card-chat {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 13px 13px 0px 0px;
    margin:-9px -9px 0 -9px;
    .first-sec {
        display: flex;
        flex-direction: row;

        .left-card-chat {
            display: flex;
            flex-direction: column;
            padding: 10px;
        }
        .right-card-chat {
            display: flex;
            flex-direction: column;
            padding: 10px;
            .right-card-chat-header {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 5px;
            }
            p {
                font-size: 12px;
                font-weight: 500;
            }
            .iconlist {
                height: 8px;
                width: 8px;
                background-color: rgb(255, 153, 0);
                border-radius: 50%;
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
    .second-sec {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;

        .left-card-sec2 {
            p {
                font-weight: 500;
                margin: 0;
            }
        }
        .right-card-sec2 {
            p {
                font-size: 14px;
                font-weight: 500;
                color: #48c5eb;
                margin: 0;
            }
        }
    }

}

.left-category {
    background-color: #f8f8f8;
    .inside-category {
        padding: 20px !important;
    }
}
.right-category {
    background-color: #fff;
    .inside-category {
        padding: 20px !important;
    }
}
.category-header-text {
    margin-bottom: 20px;
    h3{
        font-size: 18px;
    }

}
.modal-product-input {
    .ant-modal-body {
        padding: 0px !important;
    }
}

.product-list-item {
    border: 0.5px solid #d9d9d9;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.searchbox {
    border: none !important;
    height: 40px;
    padding: 15px;

    input {
        border: none;
    }
}
.modal-list-file{
    overflow: overlay;
    height: 40vh;
}
.modal-list-product{
    overflow: overlay;
    height: 48vh;
}


@media only screen and (max-width:576px) {
    .chat-register-card {
        width: 200px;
    }
}
@media only screen and (min-width:576px) {

}
@media only screen and (min-width:768px) {

}
@media only screen and (min-width:834px) {

}
@media only screen and (min-width:1024px) {

}
@media only screen and (min-width:1200px) {

}
@media only screen and (min-width:1400px) {

}
@media only screen and (min-width:1600px) {

}