.title{
    margin: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
}

.cardstyle {
    display: flex;
    flex-direction: column;
    padding: 20px !important;
    overflow: overlay;
    width: 80%;
    background-color: #ffffff;
    // height: calc(95% - 10vh);
    margin: 0;
}

.header-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
}

.card-itembox {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    margin-top: 10px;
    .itembox-header {
        background-color: #48c5eb;
        color: #fff;
        padding: 4px 10px;
        border-radius: 3px;
    }
}
.from-border-box {
    border: 0.5px solid #e1e1e1;
    border-radius: 10px;
}
.header-row {
    display: flex;
    flex-direction: row;
    align-items: end;

    h3 {
        font-size: 15px;
    }
    .ant-checkbox-inner {
        border-radius: 50%;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #37a800;
        border-color: #37a800;
        border-radius: 50%;
    }
}
.row-box {
    border-bottom: 0.5px solid #e1e1e1;
    padding: 15px;

}
.row-box-modal {
    padding: 15px;

}


@media only screen and (max-width:576px) {
    .header-card {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}