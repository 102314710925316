.table-main {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.table-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px 0;

    .ticket-chat-menu {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        align-items: center;
    }
    .left-menu {
        text-align: left;
    }
    .right-menu {
        text-align: right;
    }
}

.first-class {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 10px;
    height: calc(100vh - 60px);
    overflow: overlay;
    padding: 20px;
}
.second-class {
    display: grid;
    grid-template-columns: 100%;
    height: calc(100vh - 60px);
    overflow: overlay;
    padding: 20px;

    .second-section {
        display: none;
    }
}
.ant-modal-body > div > div > div > div > div > div > table > thead {
    height: 40px;
}

.ant-table-column-title > div > div > span {
    height: 40px;
}
.ant-select-sm .ant-select-selection--single {
    height: 40px;
}
body > div > div > div.ant-modal-wrap > div > div.ant-modal-content > div.ant-modal-body > div > div > div > div > div > div > table > thead > tr {
    height: 40px;
}
body > div > div > div.ant-modal-wrap > div > div.ant-modal-content > div.ant-modal-body > div > div > div > div > div > div > table > thead > tr > th > span {
    text-align: center;
    font-size: 16px;
}
body > div > div > div.ant-modal-wrap > div > div.ant-modal-content > div.ant-modal-body > div > div > div > div > div > div > table > tbody > tr {
    text-align: center;
}

.ant-table-column-title{
    .ant-select-selection{
        
    border: 0px !important;
    }
    .ant-select-sm .ant-select-selection__rendered {
      
        line-height: 27px;
    }
}
.filter {
    .ant-select-selection {
        border-radius: 5px !important;
    }
}

@media only screen and (max-width:834px) {
    .first-class {
        grid-template-columns:100%;
        .first-section {
            order: 2;
        }
    }
    .table-layout {
        flex-direction: column;
    }
}
@media only screen and (max-width:768px) {

}
@media only screen and (max-width:414px) {
    .table-layout .left-menu {
        text-align: center;
    }
    .table-layout .right-menu {
        text-align: center;
    }
}


.table-layout p{
    margin-bottom: 0px !important;
}


.first-section td.ant-table-row-cell-break-word .ant-checkbox-wrapper{
    position: absolute;
    margin-top: -3px;
    left: 12px;
}