

.color-item-wrapper-contactus {
  padding: 0px;
  .title {
    margin: 0px;
    font-size: 18px;
    text-align: left;
  }
  div {
    display: flex;
    border: 1px solid #dadada;
    border-radius: 5px;
    .color {
      width: 40px;
      height: 32px;
      border-radius: 5px;
      border: 1px solid #dadada;
    }
    .code {
        margin: 0px;
        padding: 6px;
        margin-left: 0px;
        margin-right: auto;
        font-size: 18px;
        
    }
  }
}

.add-button-contactus{
  width: 100%;
  background-color: #37ba00;
  color: #fff;
}
.add-button-contactus:hover {
  color: #ffffff;
  border-color: #34a803;
}