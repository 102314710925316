.customareacharts-component-wrapper {
    border-radius: 8px;
    border: 1px solid #efefef;
    width: 100%;
    .header-wrapper {
        padding: 10px;
        .title {
            font-size: 16px;
            padding: 0px;
            font-weight: 700;
        }
        .value-wrapper {
            display: flex;
            .value {
                font-size: 30px;
                font-weight: bold;
                padding: 0px;
                padding-right: 10px;
                margin-bottom: -10px;
            }
            .percentage-increase {
                padding-top: 18px;
                color: #51CF7A;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: -10px;
            }
            .percentage-decrease {
                padding-top: 18px;
                color: #EB3C19;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: -10px;
            }
        }
        .remark{
            color: #dfdad9;
        }
    }
    .graph-wrapper {
        background-color: #ffffff;
    }
}
