.video-upload-container {
    flex-flow: row wrap;
    .upload-button-wrapper {
        margin: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 200px;
        max-width: calc(50% - 10px);
        padding-top: 60px;
        height: 200px;
        border-radius: 10px;
        text-align: center;
        border: 1px solid #d9d9d9;
        cursor: pointer;
        input {
            margin-top: 10px;
        }
        p {
            font-size: 18px;
        }
    }

    .view-image-wrapper {
        margin: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        
        width: 200px;
        max-width: calc(50% - 10px);
        max-height: 200px;
        border-radius: 10px;
        text-align: center;
        border: 1px solid #d9d9d9;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 173px;
            object-fit: cover;
            border-radius: 10px 10px 0px 0px;
        }
        button {
            border: 0px solid #d9d9d9;
            border-top: 1px solid #d9d9d9;
            border-radius: 0px 0px 10px 10px;
        }
    }
}

.video-swiper-home-page {
    .video-upload-container {
        display: flex;
        margin-bottom: 100px;
        .preview-video-wrapper {
            
            width: 150px;
            height: 300px;
            margin: 5px;
        }
        .upload-button-wrapper {
            padding-top: 0px;
            width: 150px;
            height: 75px;
            p {
                font-size: 14px;
            }
            .anticon{
                font-size: 20px !important;
            }
        }
        .view-image-wrapper {
            width: 230px;
            height: 160px;
        }
    }
}