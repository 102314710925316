

.color-item-wrapper-theme {
  padding: 0px;
  .title {
    margin: 0px;
    font-size: 18px;
    text-align: left;
  }
  div {
    display: flex;
    border: 1px solid #dadada;
    border-radius: 5px;
    .color {
      width: 80px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #dadada;
    }
    .code {
        margin: 0px;
        padding: 6px;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        
    }
  }
}


.title{
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}

.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #f9f9f9 !important  ;
  height: calc(95% - 10vh);
  margin: 0;
}

.header-card-theme {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}