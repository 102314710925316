.header-component-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f2f2f2 !important;
}

.header-component-wrapper .new-menu .ant-menu {
    background: transparent !important;
}
.header-component-wrapper .new-menu p {
    margin-bottom: 0px;
    vertical-align: text-top !important;
    font-weight: 700;
}

.ant-menu-horizontal {
    border-bottom: 0px solid #e8e8e8 !important;
}

.header-right-item .header-right-item-username {
    display: inline-block;
    line-height: 20px;
    font-size: 14px;
    padding: 3px 15px;
    color: #8d97ad;
    text-align: left;
    vertical-align: top;
}
.bm-overlay .bm-menu-wrap {
    top: 0px;
}
.header-right-item .header-right-item-username p {
    margin-bottom: 0;
    font-weight: 700;
}

.header-right-item {
    display: inline-block !important;
    @media only screen and (max-width: 834px) {
        .header-right-item-avatar {
            display: none !important;
        }
        .header-right-item-username {
            display: none;
        }
    }
}
.header-right-item .header-right-item-notification {
    display: inline-block; 
    font-size: 14px;
    color: #8d97ad;
    text-align: left;
    vertical-align: top;
    margin-left: 10px; 
    margin-right: 10px; 
    cursor: pointer;
    .anticon {
        vertical-align: top;
        margin-top: 10px;
        color: #8d97ad;
    }
}
.header-right-item .header-right-item-avatar {
    display: inline-block;
    line-height: 46px;
    font-size: 18px;
    color: #8d97ad;
    vertical-align: bottom;
}
.header-right-item .header-right-item-avatar .profile-avatar {
    height: 40px;
    border-radius: 50%;
    background-size: cover;
    background-position: 50%;
}
.header-right-item .header-right-item-logout {
    display: inline-block;
    line-height: 46px;
    font-size: 14px;
    color: #8d97ad;
    text-align: left;
    vertical-align: top;
    .anticon {
        vertical-align: top;
        margin-top: 10px;
        color: #8d97ad;
    }
}

.header-right {
    text-align: right;
    padding-right: 8px;
}

.header-component-wrapper .ticket-chat-page-wrapper {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    height: 46px;
}

.header-leftmenu-wrapper {
    display: none;
}

.header-grid-space {
    display: none;
}

@media screen and (max-width: 834px) {
    .header-leftmenu-wrapper {
        display: block !important;
    }
    .header-grid-space {
        display: block;
    }
    .header-grid-left {
        .logo {
            text-align: center;
        }
    }
    .header-grid-mid {
        display: none;
    }
    .ticket-chat-page-wrapper {
        grid-template-columns: 100px 1fr 100px;
    }
}
#root
    > div
    > div.header-component-wrapper
    > div.ticket-chat-page-wrapper
    > div.header-grid-mid
    > div
    > ul
    > li.ant-menu-item {
    border-radius: 0px !important;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 4px;
}
