.header-card-setting {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.cardstyle {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  overflow: overlay;
  width: 100%;
  background-color: #fff !important  ;
  height: calc(95% - 0vh);
  margin: 0;

  .package-header {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 30px;
    span {
      color: #47C5EB;
    }
  }

  .progress-container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 10px;
  }
  .progress-box {
    padding: 20px;
    border-radius: 10px;  
    position: relative;
    .progress-title {
      display: flex;
      justify-content: center;
      h3 {
        font-size: 23px;
        margin-bottom: 24px;
        color: #363636;
      }
    }
    .custom-progress {
      display: flex;
      flex-direction: row;
  
      .first-p {
        position: absolute;
        z-index: 3;
        margin-left: 20px;
        margin-top: 3px;
        color: #fff;
      }
      .progrss-bar {
        position: relative;
      }
      .last-p {
        position: absolute;
        z-index: 3;
        margin-right: 50px;
        margin-top: 3px;
        right: 0;
      }
    }
    .progress-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px ;
      .divider {
        border-left: 3px;
        height: 35px;
      }
      .progress-info-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        h3 {
          margin: 0;
          color: #363636;
        }
      }
    }
  }
  // section 2
  .setting-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #f9f9f9;
    align-items: center;
    padding: 30px;
    border-radius: 5px;
    .setting-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 10px;

      p{ 
        margin-bottom: 0;
        margin-right: 10px;
        width: 131px;
      }
    }
  }
  // section 3
  .layout-container {
    display: grid;
    grid-template-columns: 300px 2fr;
    padding: 10px;
    margin-bottom: 20px;

    .right-layout {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .layout-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .ant-checkbox-inner {
          border-radius: 50%;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #48c5eb;
            border-color: #48c5eb;
            border-radius: 50%;
        }
      }
    }
    .left-layout {
      display: grid;
      grid-template-columns: 1fr;
      .item-menu-box {
        .menu-box-item {
          display: flex;
          flex-direction: row;
          padding: 15px;
          background-color: #48c5eb;
          border-radius: 5px;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          img{
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
          p {
            margin: 0px;
            color: #fff;
            font-size: 16px;
          }
        }
      }
    }
  }
  // section 4
  .layout-setting-container {
    display: flex;
    flex-direction: column;

    .layout-setting-item {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 10px;
      padding: 20px 20px 10px;
      background-color: #f9f9f9;
      border-radius: 5px;
  
    }
  }
}

.layout-setting-item{
  .ant-row{
    margin-bottom: 2px !important;
  }
}
.color-item-wrapper {
  padding: 20px;
  .title {
    margin: 0px;
    padding: 5px;
    font-size: 18px;
  }
  div {
    display: flex;
    border: 1px solid #F4F4F4;
    border-radius: 3px;
    .color {
      width: 80px;
      height: 40px;
      border-radius: 0px;
      border: 0px;
      border-right: 1px solid #F4F4F4;
    }
    .code {
        margin: 0px;
        padding: 6px;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
    }
  }
}


.layout-wrapper{
  text-align: center;
  padding: 5px;
    .ant-row{
      color: #000000;
      .ant-col{ 
        vertical-align: middle;
        line-height: 50px;
        background-color: #ccf4ff;
        border: 1px solid #ffffff;
      }
    }
}

.layout-mobile-wrapper{

}
@media only screen and (max-width:576px) {
  .cardstyle .progress-container {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .cardstyle .setting-container {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }
  .cardstyle .layout-container {
    grid-template-columns: 1fr;
  }
  .cardstyle .layout-container .right-layout {
    grid-template-columns: 1fr;
  }
  .cardstyle .layout-setting-container .layout-setting-item {
    flex-direction: column;
  }

}
@media only screen and (min-width:576px) {
  .cardstyle .progress-container {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .cardstyle .setting-container {
    flex-wrap: wrap;
  }
  .cardstyle .layout-container {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width:768px) {
  .cardstyle .progress-container {
    grid-template-columns: repeat(3,1fr);
    grid-gap: 10px;
  }
  .cardstyle .setting-container {
    flex-wrap: wrap;
  }
  .cardstyle .layout-container {
    grid-template-columns: 1fr;
  }
  .cardstyle .layout-setting-container .layout-setting-item {
    flex-direction: column;
  }
}

@media only screen and (min-width:834px) {

}
@media only screen and (min-width:1024px) {
  .cardstyle .layout-container {
    grid-template-columns: 300px 2fr;
  }
  .cardstyle .layout-setting-container .layout-setting-item {
    flex-direction: row;
  }
}
@media only screen and (min-width:1200px) {

}
@media only screen and (min-width:1400px) {

}
@media only screen and (min-width:1600px) {

}