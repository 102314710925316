.forgetpassword-page-wrapper {
  display: flex;
  flex-direction: row;
  .left-colunm-wrapper {
    background-color: #65D2F3;
    background-image: url('../../resource/assets/loginleftbg.png');
    min-height: 100vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 100px 5% 50px 5%;
    @media only screen and (max-width:834px) {
      display: none;
    }
    .logo{
      display: flex;
      position: absolute;
      top:8%;
    }
    .left-pic{
margin:0 auto;
    }
    .title {
      margin: 20px 0;
      font-size: 64px;
      font-weight: 500;
    }
    .title-forgot {
      margin: 20px 0;
      font-size: 40px;
      font-weight: 200;
    }
    .border-title {
      border-bottom: 15px solid #48c5eb;
      height: 10px;
      width: 150px;
    }
    .sub-title {
      font-size: 34px;
      line-height: 2.5rem;
      text-align: center;
      margin: 0;
      color: #fff;
    }
    .sub-title-forgot {
      font-size: 22px;
      line-height: 2rem;
      margin: 0;
      span {
        color: #48c5eb;
      }
    }
    .sub-title-2{
      font-size: 24px;
      line-height: 2.5rem;
      margin: 0; 
    }
    .sub-title-2-forgot{
      font-size: 18px;
      line-height: 2rem;
      margin: 0; 
    }
    .circle {
      height: 10px;
      width: 10px;
      background-color: #000;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }
    .devider-forgot {
      height: 20px;
    }
    .login-footer {
      position: absolute;
      bottom: 40px;
      text-align: center;
      color: #fff;
    }
  }
  .right-colunm-wrapper {
    background-color: #fcfcfc;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logo{
        display: none;
      }
      .title {
        margin: 10px 0;
        font-size: 55px;
        font-weight: 400;
        .title-bold{
          font-weight: 500;
        }
      }
      .sub-title{
        font-size: 18px;
        line-height: 2.5rem;
        font-weight: 400;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 10px;
        text-align: center;
        .sub-title-blue{

        }
      }
      .logo {
        text-align: center;
        width: 100%;
        margin-bottom: 50px;
        img{
           max-width: 100%;
        }
       
      }
      .input-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 450px;
        padding: 20px;
        .input-login {
          margin-bottom: 10px  ;
        }
        .input-login::placeholder {
          color: #eaeaea;
        }
        .ant-input-affix-wrapper{
          font-size: 20px !important;
        }
        .ant-input-lg {
          height: 55px;
          font-size: 20px !important;
          padding-left: 40px !important;
      }
        button {
          padding: 15px 10px !important;
          margin: 20px 0;
          border-radius: 4px !important;
          font-size: 18px !important;
          border: none;
          background: rgb(0,196,255);
          background: linear-gradient(0deg, rgba(0,196,255,1) 0%, rgba(63,197,237,1) 100%);
          color: #fff;
          font-weight: 500;
        }
      }
    }
  }
}


@media only screen and (min-width:1440px) {

  .input-group {
    width: 60%;
  }
}
@media only screen and (max-width:1280px) {

}
@media only screen and (max-width:1024px) {
  .forgetpassword-page-wrapper .left-colunm-wrapper {
    justify-content: center;
    padding: 50px;
  }
  .forgetpassword-page-wrapper .left-colunm-wrapper .login-footer {
    margin-right: 50px;
  }
}
@media only screen and (max-width:834px) {
  .left-colunm-wrapper {
    display: none !important;
  }
  .right-colunm-wrapper {
    width: 100% !important;
    .logo{
      display: block !important;
      
    }
  }
}
@media only screen and (max-width:768px) {

}
@media only screen and (max-width:414px) {
  .forgetpassword-page-wrapper .right-colunm-wrapper .container .input-group {
    width: 100% ;
  }
}
@media only screen and (max-width:375px) {

}