.title{
    margin: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
}

.cardstyle {
    display: flex;
    flex-direction: column;
    padding: 20px !important;
    overflow: overlay;
    width: 100%;
    background-color: #ffffff;
    height: calc(95% - 10vh);
    margin: 0;
}
.header-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
}