.login-page-wrapper {
  display: flex;
  flex-direction: row;
  .left-colunm-signup-wrapper {
    background-color: #65D2F3;
    background-image: url('../../resource/assets/loginleftbg.png');
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 100px 0 50px 5%;
    @media only screen and (max-width:834px) {
      display: none;
    }
    .logo{
      position: absolute;
      top:8%;
    }
    .title {
      margin: 10px 0;
      font-size: 70px;
      font-weight: 400;
    }
    .border-title {
      border-bottom: 12px solid #48c5eb;
      
      margin-left:12px;
      width: 100px;
      margin-bottom: 30 px;
    }
    .login-text{
    }
    .sub-title {
      font-size: 32px;
      line-height: 50px;
      font-weight: 400;
      margin: 0;
      color: #fff;
    }
    .sub-title-2{
      font-size: 42px;
      line-height: 50px;
      font-weight: 700;
      margin: 0;
      color: #fff;
    }
    .sub-title-3{

      font-size: 18px;
      line-height: 2.5rem;
      font-weight: 400;
      margin: 0;
      margin-top: 70px;
      color: #fff;
    }
    .signup-button{
      background-color: #ffffff;
      border-radius: 20px;
      margin-top: 20px;
       padding: 10px 20px;
       max-width: 200px;
       font-size: 18px;
       text-align: center;
       font-weight: 500;
       transition: 0.3s;
       &:hover{
        background-color: #e4e4e4;
     
       }
       p{
         margin: 0;
         
       }
      
    }
    .login-footer {
      position: absolute;
      bottom: 8%;
      text-align: left;
      color: #fff;
    }
  }
  .right-colunm-signup-wrapper {
    background-color: #fcfcfc;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logo{
        display: none;
      }
      .title {
        margin: 10px 0;
        font-size: 55px;
        font-weight: 400;
        .title-bold{
          font-weight: 500;
        }
      }
      .sub-title{
        font-size: 18px;
        line-height: 2.5rem;
        font-weight: 400;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 10px;
        text-align: center;
        .sub-title-blue{

        }
      }
      .logo {
        text-align: center;
        width: 100%;
        margin-bottom: 50px;
        img{
           max-width: 100%;
        }
       
      }
      .input-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 450px;
        padding: 20px;
        .input-login {
          margin-bottom: 10px  ;
        }.input-login::placeholder {
          color: #eaeaea;
        }
        .ant-input-affix-wrapper{
          font-size: 20px !important;
        }
        .ant-input-lg {
          height: 55px;
          font-size: 20px !important;
          padding-left: 40px !important;
      }
        button {
          padding: 15px 10px !important;
          margin: 20px 0;
          border-radius: 4px !important;
          font-size: 18px !important;
          border: none;
          background: rgb(0,196,255);
          background: linear-gradient(0deg, rgba(0,196,255,1) 0%, rgba(63,197,237,1) 100%);
          color: #fff;
          font-weight: 500;
        }
      }
    }
  }
}


@media only screen and (min-width:1440px) {
  .login-page-wrapper .left-colunm-wrapper {
    padding: 150px 0 50px 100px;
  }
  .input-group {
    width: 60%;
  }
}
@media only screen and (max-width:1280px) {

}
@media only screen and (max-width:1024px) {
  .login-page-wrapper .left-colunm-wrapper {
    justify-content: center;
    padding: 50px;
  }
  .login-page-wrapper .left-colunm-wrapper .login-footer {
    margin-right: 50px;
  }
}
@media only screen and (max-width:834px) {
  .left-colunm-wrapper {
    display: none !important;
  }
  .right-colunm-wrapper {
    width: 100% !important;
    .logo{
      display: block !important;
    }
  }
}
@media only screen and (max-width:768px) {

}
@media only screen and (max-width:414px) {
  .login-page-wrapper .right-colunm-wrapper .container .input-group {
    width: 100% ;
  }
}
@media only screen and (max-width:375px) {

}