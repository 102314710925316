.paymentorder-sub-page-wrapper {
    .action-button-wrapper {
        padding: 10px 15px;
        .title-header {
            font-size: 20px;
            font-weight: bold;
            margin: 0px;
        }
        .create-button {
            color: #ffffff;
            background-color: #37ba00;
        }
        .create-button:hover {
            color: #ffffff;
            border-color: #34a803;
        }
        .create-button:disabled {
            color: #00000040;
            background-color: #f5f5f5;
            border-color: #f5f5f5;
        } 
    }
}
