.title{
    margin: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
}

.cardstyle {
    display: flex;
    flex-direction: column;
    padding: 20px !important;
    overflow: overlay;
    width: 100%;
    background-color: #ffffff;
    height: calc(95% - 10vh);
    margin: 0;
}

.header-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
}


.deliverymethod-page-wrapper{
    .action-button-wrapper {
        padding: 10px 15px;
        .title-header {
            font-size: 20px;
            font-weight: bold;
            margin: 0px;
        }
        .create-button {
            color: #ffffff;
            background-color: #37ba00;
        }
        .create-button:hover {
            color: #ffffff;
            border-color: #34a803;
        }
    }
}